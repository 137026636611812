import React, { useEffect, useRef, useState } from "react"
import { Loader2, XCircle, Settings } from "lucide-react"
import {
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "react-query"
import { extractNameAndTeam } from "../utils"

interface TPlayer {
    name: string
    image_data: string
    remove_bg: boolean
    team_id?: string
    remove_bg_opts?: { ai_lighting: Boolean; despill: Boolean }
}

interface TReturnPlayer {
    name: string
    team_id: string
    original_photo: string
    deetched_photo: string
    upscaled_photo: string
    final_photo: string
    created_at: string
}

function PreviewProcessedPhotos({
    image,
    playerImage,
    setPlayerImage,
    setPlayerMeta,
    setShowForm,
    playerId,
    playerName,
    setPlayerName,
    setSecondaryLine,
}) {
    const [photoDimensions, setPhotoDimensions] = useState({
        width: 0,
        height: 0,
    })
    const isSelected = image === playerImage
    const cx = [
        "col-span-1 border-2 rounded-md",
        "flex flex-col items-center",
        isSelected
            ? "border-primary bg-primary text-white"
            : "border-white bg-white text-primary",
        "relative h-full",
    ].join(" ")

    useEffect(() => {
        if (image) {
            const img = document.createElement("img")
            img.crossOrigin = "anonymous"
            img.src = image
            img.onload = function () {
                const w = img.width
                const h = img.height
                setPhotoDimensions({ width: w, height: h })
            }
        }
    }, [image])

    const { name, team } = extractNameAndTeam(playerName)
    function handleClick() {
        setPlayerMeta({ ...photoDimensions, playerId })
        setPlayerName(name ?? "Player Name")
        setSecondaryLine(team ?? "Secondary Line")
        setPlayerImage(image)
        setShowForm(false)
    }

    return (
        <div className={cx}>
            <img src={image} alt="processed" className="my-auto" height={80} />
            <div className="flex w-full items-center p-1 mt-auto gap-2">
                <button
                    onClick={handleClick}
                    className="flex flex-col bg-primary hover:bg-indigo-700 uppercase w-full text-white rounded-md flex items-center justify-center px-2 py-1 overflow-hidden"
                >
                    {isSelected ? "Selected" : "Select"}{" "}
                    <span className="w-full truncate px-2">{name}</span>
                </button>
            </div>
        </div>
    )
}

function Preview({
    image,
    setPlayerImage,
    setPlayerMeta,
    playerImage,
    setShowForm,
    removeFromImages,
    filename,
}) {
    const [photoDimensions, setPhotoDimensions] = useState({
        width: 0,
        height: 0,
    })
    const [objectURL, setObjectURL] = useState(null)
    const queryClient = useQueryClient()
    const { mutate, isLoading } = useMutation({
        mutationFn: async (player: TPlayer) => {
            await fetch(`/api/players`, {
                method: "POST",
                headers: { "Content-type": "application/json; charset=UTF-8" },
                body: JSON.stringify(player),
            })
        },
        onSettled: async () =>
            await queryClient.invalidateQueries({
                queryKey: ["uploadedPhotos"],
            }),
        mutationKey: ["addUploadedPhoto"],
    })

    const cx = [
        "col-span-1 border-2 rounded-md",
        "flex flex-col items-center",
        "border-white bg-white text-primary",
        "relative h-full",
    ].join(" ")

    useEffect(() => {
        const reader = new FileReader()
        reader.readAsDataURL(image)
        reader.onload = function () {
            setObjectURL(reader.result)
        }
        reader.onerror = function (error) {
            throw error
        }
    }, [])

    useEffect(() => {
        if (objectURL) {
            const img = document.createElement("img")
            img.crossOrigin = "anonymous"
            img.src = objectURL
            img.onload = function () {
                const w = img.width
                const h = img.height
                setPhotoDimensions({ width: w, height: h })
            }
        }
    }, [objectURL])

    function handleClick() {
        setPlayerMeta({ ...photoDimensions, playerId: "" })
        setPlayerImage(objectURL)
        setShowForm(false)
    }

    function uploadPhoto(
        remove_bg: boolean,
        ai_lighting = false,
        despill = false
    ): void {
        mutate({
            name: filename.replace(/(.*)\.(.*?)$/, "$1").trim(),
            image_data: objectURL as unknown as string,
            remove_bg,
            ...(remove_bg
                ? {
                      remove_bg_opts: {
                          ai_lighting,
                          despill,
                      },
                  }
                : {}),
        })
    }

    return (
        <div className={cx}>
            <button
                className="absolute top-1 right-1 h-4 w-4 text-red-500"
                onClick={removeFromImages}
            >
                <XCircle className="h-4 w-4" />
            </button>

            <img
                src={objectURL}
                alt="Selected"
                className="my-auto"
                height={80}
            />
            <div className="flex flex-col w-full items-center p-1 mt-auto gap-2">
                <button
                    className="mb-2 bg-primary hover:bg-indigo-700 disabled:bg-slate-300 uppercase w-full text-white rounded-md flex items-center justify-center px-2 py-1 disabled:bg-gray"
                    onClick={() => uploadPhoto(false)}
                    disabled={!objectURL || isLoading}
                >
                    {isLoading && (
                        <Loader2 className="animate-spin mr-0.5 shrink-0 w-4 h-4" />
                    )}
                    <span className="ml-1">
                        {isLoading ? "Uploading..." : "Upload"}
                    </span>
                </button>
                <form
                    className="w-full flex flex-col gap-1"
                    onSubmit={(e) => {
                        e.preventDefault()

                        const fd = new FormData(e.currentTarget)

                        const despill = fd.get("despill") === "on"
                        const lighting = fd.get("lighting") === "on"
                        uploadPhoto(true, lighting, despill)
                    }}
                >
                    <span className="flex items-center gap-2 text-sm">
                        <Settings size={14} />
                        De-etch&nbsp;options
                    </span>
                    <div className="flex items-center gap-2 text-sm">
                        <input type="checkbox" name="despill" id="despill" />
                        <label htmlFor="despill">Despill</label>
                    </div>
                    <div className="flex items-center gap-2 text-sm">
                        <input type="checkbox" name="lighting" id="lighting" />
                        <label htmlFor="lighting">Lighting</label>
                    </div>
                    <button
                        className="bg-primary hover:bg-indigo-700 disabled:bg-slate-300 uppercase w-full text-white rounded-md flex items-center justify-center px-2 py-1 disabled:bg-gray"
                        type="submit"
                        disabled={!objectURL || isLoading}
                    >
                        {isLoading && (
                            <Loader2 className="animate-spin mr-0.5 shrink-0 w-4 h-4" />
                        )}
                        <span className="ml-1">
                            {isLoading ? `De‑Etching...` : "De-Etch"}
                        </span>
                    </button>
                </form>
            </div>
        </div>
    )
}

function tempImgId(img) {
    return `${img.name}-${img.lastModified}`
}

const fetchPlayers = async () => {
    const res = await fetch("/api/players/today")
    return await res.json()
}

function PlayerImageUploader({
    playerImage,
    setPlayerImage,
    setPlayerMeta,
    setPlayerName,
    setSecondaryLine,
}) {
    const result: UseQueryResult<TReturnPlayer[]> = useQuery({
        queryKey: ["uploadedPhotos"],
        queryFn: fetchPlayers,
        staleTime: Infinity,
    })
    const uploadedPhotos = result?.data

    const [images, setImages] = useState([])
    const [showForm, setShowForm] = useState(false)
    const ref = useRef()
    useEffect(() => {
        function onClickOutside(e) {
            if (!(ref.current as HTMLElement).contains(e.target)) {
                setShowForm(false)
            }
        }

        document.addEventListener("click", onClickOutside)

        return () => document.removeEventListener("click", onClickOutside)
    }, [])

    const handleImageChange = (e) => {
        const files = e.target.files
        setImages(images.concat(...files))
    }

    const handleDragOver = (e) => {
        e.preventDefault()
    }

    const handleDrop = (e) => {
        e.preventDefault()

        const files = e.dataTransfer.files
        setImages(images.concat(...files))
    }

    const removeFromImages = (img, e) => {
        e.stopPropagation()
        const newImages = images.filter(
            (image) => tempImgId(image) !== tempImgId(img)
        )
        setImages(newImages)
    }

    const cx = [
        "border flex items-center px-4 py-2 rounded-md bg-white",
        showForm ? "border-b-0 rounded-b-none z-20" : "",
    ].join(" ")
    return (
        <div className="flex flex-col gap-2 relative bg-white" ref={ref}>
            <button
                className={cx}
                type="button"
                onClick={() => setShowForm(!showForm)}
            >
                Images
            </button>
            {showForm && (
                <div className="z-10 max-h-[600px] overflow-auto grid grid-cols-3 gap-2 w-[600px] absolute top-10 bg-white border shadow-md rounded-b-md rounded-r-md p-4">
                    <div
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                        className="col-span-full border-2 p-4 rounded-md flex flex-col items-center justify-center py-8"
                    >
                        <p>
                            Drag and drop an image here, or click to select one
                        </p>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            style={{ display: "none" }}
                            id="fileInput"
                            multiple
                        />
                        <label
                            htmlFor="fileInput"
                            className="cursor-pointer font-bold hover:text-primary"
                        >
                            Select Image
                        </label>
                    </div>
                    {images.length > 0 && (
                        <div className="grid grid-cols-4 col-span-full">
                            <p className="col-span-full font-bold">
                                Photos for checking
                            </p>
                            {images.map((img) => {
                                const id = tempImgId(img)
                                return (
                                    <Preview
                                        key={id}
                                        image={img}
                                        setPlayerImage={setPlayerImage}
                                        setPlayerMeta={setPlayerMeta}
                                        playerImage={playerImage}
                                        setShowForm={setShowForm}
                                        filename={img?.name}
                                        removeFromImages={removeFromImages.bind(
                                            null,
                                            img
                                        )}
                                    />
                                )
                            })}
                        </div>
                    )}
                    {uploadedPhotos?.length > 0 && (
                        <div className="grid grid-cols-4 col-span-full">
                            <p className="col-span-full font-bold">
                                Processed Photos
                            </p>
                            {uploadedPhotos
                                ?.sort(
                                    (a, b) =>
                                        new Date(b.created_at) -
                                        new Date(a.created_at)
                                )
                                .map((p) => (
                                    <PreviewProcessedPhotos
                                        key={p.id}
                                        image={
                                            p.deetched_photo ?? p.final_photo
                                        }
                                        setShowForm={setShowForm}
                                        setPlayerMeta={setPlayerMeta}
                                        setPlayerName={setPlayerName}
                                        setPlayerImage={setPlayerImage}
                                        playerName={p.name}
                                        playerImage={playerImage}
                                        playerId={p.id}
                                        setSecondaryLine={setSecondaryLine}
                                    />
                                ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default PlayerImageUploader
