import React from "react"
import { MAX_DEG, MIN_DEG } from "../utils/CONSTANTS"
import { RotateCcwIcon } from "lucide-react"

function TextRotationInput({ name, rotation, setRotation }) {
    return (
        <div className="flex w-1/2 flex-col">
            <div className="flex w-full items-center gap-1">
                <label className="mb-1 text-xs uppercase min-w-[200px]">Rotation <span className="font-bold">{rotation + "°"}</span></label>
                <button
                    className="bg-transparent text-slate-500 hover:text-white hover:bg-slate-500 rounded-md p-2"
                    type="reset"
                    title="Reset X"
                    onClick={() => setRotation(0)}
                >
                    <RotateCcwIcon className="w-4 h-4"/>
                </button>
            </div>
            <input
                type="range"
                name={`${name}.rotation`}
                onChange={(e) => setRotation(Number(e.target.value))}
                min={MIN_DEG}
                step={0.5}
                max={MAX_DEG}
                value={rotation}
            />
        </div>
    )
}

export default TextRotationInput
