import React from "react"
import { useRouterState } from "@tanstack/react-router"
import SocialTileView from "./SocialTileView"

function TileCreator() {
    const { location } = useRouterState()

    if (!location.state.image) {
        return <div>No Card Present</div>
    }

    return (
        <div className="min-h-[calc(100dvh-160px)] w-full flex flex-col items-center justify-center">
            <SocialTileView
                image={location.state.image}
                width={location.state.width}
                height={location.state.height}
                playerName={location.state.playerName}
                x={200}
                y={46}
            />
        </div>
    )
}

export default TileCreator
