import React, { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"

function ToolbarPopover({
    open,
    title,
    children,
    icon,
    onButtonClick = () => {},
}) {
    return (
        <>
            <Popover.Button
                type="button"
                className={`w-full hover:bg-primary hover:text-white px-2 py-2 flex items-center justify-center ${
                    open ? "text-white bg-primary" : "text-slate-500"
                }`}
                title={title}
                onClick={onButtonClick}
            >
                {icon}
            </Popover.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1 translate-x-1"
            >
                {children}
            </Transition>
        </>
    )
}

export default ToolbarPopover
