import { useAtom, useAtomValue } from "jotai"
import { selectedTextAtom, textLayersAtom } from "../../template_creator/atoms"

function useAnchorPoint() {
    const selectedText = useAtomValue(selectedTextAtom)
    const [textLayers, setTextLayers] = useAtom(textLayersAtom)

    function updateAnchorPoint(anchorPoint) {
        if (anchorPoint === "rotater") {
            return null
        }

        const [verticalAlign, align] = anchorPoint.split("-")
        const newTextLayers = textLayers.map((t) => {
            if (t.id === selectedText) {
                return {
                    ...t,
                    align,
                    verticalAlign,
                }
            } else {
                return t
            }
        })
        setTextLayers(newTextLayers)
    }

    return {
        updateAnchorPoint,
    }
}

export default useAnchorPoint
