import React from "react"
import { useAtom } from "jotai"
import { Popover } from "@headlessui/react"
import { selectedTextAtom, textLayersAtom } from "../atoms"
import { TypeIcon } from "lucide-react"
import ToolbarPopover from "./TooolbarPopover"
import useResetTextSelection from "../../components/hooks/useResetTextSelection"

function TextLayerButton({ isSelected, title, onClick }) {
    return (
        <button
            type="button"
            title={title}
            onClick={onClick}
            className={`${
                isSelected ? "bg-primary text-white" : "bg-white text-slate-500"
            } rounded-md px-2 py-1 capitalize`}
        >
            {title}
        </button>
    )
}

function TextLayerSelection() {
    const resetTextSelection = useResetTextSelection()
    const [selectedText, setSelectedText] = useAtom(selectedTextAtom)
    const [textLayers, setTextLayers] = useAtom(textLayersAtom)

    function onClick(id) {
        if (selectedText === id) {
            resetTextSelection()
        } else {
            setSelectedText(id)
        }
    }

    function toggleText(id) {
        const updatedLayers = textLayers.map((t) => {
            if (t.id === id) {
                return {
                    ...t,
                    hidden: !t.hidden,
                }
            }

            return t
        })
        setTextLayers(updatedLayers)
    }

    return (
        <Popover className="relative">
            {({ open }) => (
                <ToolbarPopover
                    open={open}
                    title="Text Layer"
                    icon={<TypeIcon />}
                >
                    <Popover.Panel className="z-100 left-12 top-0 absolute bg-slate-100 shadow-md w-48 p-4">
                        <div className="flex flex-wrap w-full flex-col gap-2">
                            <p className="uppercase font-bold">
                                Select Text Layer
                            </p>
                            {textLayers
                                .map((t, i) => (
                                    <div
                                        key={i}
                                        className="flex items-center gap-2"
                                    >
                                        <input
                                            type="checkbox"
                                            onChange={toggleText.bind(null, t.id)}
                                            checked={!t.hidden}
                                        />
                                        <TextLayerButton
                                            isSelected={t.id === selectedText}
                                            onClick={onClick.bind(null, t.id)}
                                            title={t.id?.split("-").join(" ")}
                                        />
                                    </div>
                                ))}
                        </div>
                    </Popover.Panel>
                </ToolbarPopover>
            )}
        </Popover>
    )
}

export default TextLayerSelection
