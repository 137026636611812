import React, { useEffect, useRef, useState } from "react"
import { Group, Rect, Text, Transformer } from "react-konva"
import { CARD_BLEED_X, CARD_BLEED_Y } from "../utils/CONSTANTS"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import {
    selectedBgAtom,
    selectedFrontImageAtom,
    selectedTextAtom,
    selectedTextNodeAtom,
    textLayersAtom,
} from "./atoms"
import {
    SCALE,
    clipFunc,
    innerHeight,
    innerWidth,
    originalHeight,
    originalWidth,
} from "./layout"
import useAnchorPoint from "../components/hooks/useAnchorPoint"
import BackgroundImage from "../components/BackgroundImage"
import ForegroundImage from "../components/ForegroundImage"
import debounce from "lodash/debounce"
import Konva from "konva"

// function SampleImage({
//     src,
//     width,
//     height,
//     ...p
// }: {
//     src: string
//     width: number
//     height: number
// }) {
//     const [image] = useImage(src, "anonymous")

//     if (!image) {
//         return null
//     }
//     return <Image image={image} width={width} height={height} />
// }

const MIN_FONT_SIZE = 14
function calculateFontSize(
    text,
    { width },
    fontName,
    startingFontSize = 10,
    letterSpacing
) {
    const canvas = document.createElement("canvas")
    const context = canvas.getContext("2d") as CanvasRenderingContext2D
    let fontSize = Number(startingFontSize)

    const letterSpacingInPx = (letterSpacing / 100) * fontSize
    context.font = fontSize + "px " + fontName
    context.letterSpacing = letterSpacingInPx + "px"

    const maxWidth = letterSpacingInPx !== 0 ? width - (letterSpacingInPx * 10) : width 
    while (
        context.measureText(text).width > maxWidth &&
        fontSize >= MIN_FONT_SIZE
    ) {
        fontSize--
        context.font = fontSize + "px " + fontName
    }

    return Math.max(fontSize, MIN_FONT_SIZE)
}

function getAnchorName(n) {
    return n.split(" ")
}

const clipFuncWithScale = clipFunc(SCALE)

function TextContainer({ index, trRef, ...props }) {
    const { updateAnchorPoint } = useAnchorPoint()
    const [selectedText, setSelectedText] = useAtom(selectedTextAtom)
    const setSelectedTextNode = useSetAtom(selectedTextNodeAtom)
    const isSelected = selectedText === props.id
    const [textLayers, setTextLayers] = useAtom(textLayersAtom)
    const textRef = useRef()

    function handleDragEnd(e) {
        const p = {
            x: e.target.x(),
            y: e.target.y(),
        }
        const updatedTargets = textLayers.map((t, i) =>
            i === e.target.index ? { ...t, ...p } : t
        )
        setTextLayers(updatedTargets)
    }

    function onTransformEnd(e) {
        // transformer is changing scale of the node
        // and NOT its width or height
        // but in the store we have only width and height
        // to match the data better we will reset scale on transform end
        const node = textRef.current as unknown as Konva.Text
        const scaleX = node.scaleX()
        const scaleY = node.scaleY()
        const rotation = node.rotation()
        // we will reset it back
        node.scaleX(1)
        node.scaleY(1)
        const p = {
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
            rotation,
        }
        const updatedTargets = textLayers.map((t, i) =>
            i === e.target.index ? { ...t, ...p } : t
        )
        setTextLayers(updatedTargets)
    }

    function onTransform() {
        const text = textRef.current as unknown as Konva.Text
        const absScale = text.getAbsoluteScale()
        const scaleX = text.scaleX()
        const scaleY = text.scaleY()
        const width = Math.max(5, text.width() * scaleX)
        const height = Math.max(text.height() * scaleY)
        text.scaleX(scaleX / absScale.x)
        text.scaleY(scaleY / absScale.y)
        text.width(width)
        text.height(height)
    }

    function handleClick() {
        setSelectedText(props.id)
    }

    useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            const node = textRef.current as unknown as Konva.Text
            trRef.current.nodes([node])
            trRef.current.getLayer().batchDraw()
            setSelectedTextNode(node)
        }
    }, [isSelected])

    function fitTextToWidth() {
        if (isSelected) {
            const node = textRef.current as unknown as Konva.Text
            const newFontSize = calculateFontSize(
                node.attrs.text,
                { width: node.attrs.width},
                node.attrs.fontFamily,
                node.attrs.fontSize,
                node.attrs.letterSpacing
            )
            const updatedTargets = textLayers.map((t, i) =>
                i === node?.index ? { ...t, fontSize: newFontSize } : t
            )
            setTextLayers(updatedTargets)
        }
    }

    const debouncedFitText = debounce(fitTextToWidth, 400)

    useEffect(() => {
        fitTextToWidth()
    }, [
        props?.fontSize,
        props?.width,
        isSelected,
        props?.fontFamily,
        props?.align,
        props?.text,
        props?.letterSpacing,
    ])

    return (
        <>
            <Text
                ref={textRef}
                {...props}
                onDragEnd={handleDragEnd}
                onTransform={onTransform}
                onTransformEnd={onTransformEnd}
                draggable={isSelected}
                onDblClick={handleClick}
                wrap="none"
                ellipsis={true}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    flipEnabled={false}
                    padding={4}
                    anchorStyleFunc={(e) => {
                        const [anchor] = getAnchorName(e.attrs.name)
                        const currentAnchor = `${props.verticalAlign}-${props.align}`
                        if (currentAnchor === anchor) {
                            e.fill("#4db2ec")
                        } else {
                            e.fill("white")
                        }
                    }}
                    onDblClick={(e) => {
                        const targetName = e.target.attrs.name
                        const [anchor] = getAnchorName(targetName)
                        updateAnchorPoint(anchor)
                    }}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (
                            Math.abs(newBox.width) < 5 ||
                            Math.abs(newBox.height) < 5
                        ) {
                            return oldBox
                        }
                        return newBox
                    }}
                />
            )}
        </>
    )
}

function Card() {
    const [dataUrl, setDataUrl] = useState<string | null>(null)
    const textLayers = useAtomValue(textLayersAtom)
    const background = useAtomValue(selectedBgAtom)
    const foreground = useAtomValue(selectedFrontImageAtom)
    const shadowRef = useRef()
    const imageLayersRef = useRef()
    const textLayersRef = useRef()
    const textLayersRefCopy = useRef()
    const trRef = useRef()

    useEffect(() => {
        const tCopy = textLayersRefCopy.current as unknown as Konva.Group
        tCopy.opacity(1)
        const dataURL = tCopy?.toDataURL({
            pixelRatio: 1 / SCALE,
            mimeType: `image/png`,
        })
        tCopy.opacity(0)
        setDataUrl(dataURL)
    })

    return (
        <>
            <Rect
                ref={shadowRef}
                fill="black"
                opacity={0.5}
                shadowColor="black"
                shadowBlur={10}
                shadowOpacity={0.5}
                cornerRadius={16}
                shadowOffsetX={4}
                shadowOffsetY={4}
                listening={false}
                width={innerWidth}
                height={innerHeight}
                x={CARD_BLEED_X * SCALE}
                y={CARD_BLEED_Y * SCALE}
            />
            <Group
                ref={imageLayersRef}
                clipFunc={clipFuncWithScale}
                x={0}
                y={0}
                listening={false}
            >
                <BackgroundImage
                    src={background?.image}
                    width={originalWidth}
                    height={originalHeight}
                />
                <ForegroundImage
                    src={foreground?.image}
                    width={originalWidth}
                    height={originalHeight}
                />
            </Group>
            <Group
                ref={textLayersRef}
                width={originalWidth}
                height={originalHeight}
                x={0}
                y={0}
            >
                {textLayers.filter(t => !t.hidden).map((t, i) => {
                    return (
                        <TextContainer key={i} index={i} trRef={trRef} {...t} />
                    )
                })}
            </Group>
            <Group
                ref={textLayersRefCopy}
                width={originalWidth}
                height={originalHeight}
                opacity={0}
                listening={false}
                x={0}
                y={0}
                clipFunc={clipFuncWithScale}
            >
                <Rect
                    fill="transparent"
                    width={originalWidth}
                    height={originalHeight}
                />
                {textLayers.map((t, i) => {
                    return (
                        <TextContainer
                            key={i}
                            index={i}
                            trRef={trRef}
                            {...t}
                            listening={false}
                            id={t?.id + "-copy"}
                        />
                    )
                })}
            </Group>
        </>
    )
}

export default Card
