import {
    CARD_BLEED_X,
    CARD_BLEED_Y,
    CARD_HEIGHT,
    CARD_HEIGHT_W_BLEED,
    CARD_WIDTH,
    CARD_WIDTH_W_BLEED,
    SCALE,
    POSTER_BLEED_X,
    POSTER_BLEED_Y,
    POSTER_HEIGHT,
    POSTER_HEIGHT_W_BLEED,
    POSTER_WIDTH,
    POSTER_WIDTH_W_BLEED,
    POSTER_SCALE,
} from './CONSTANTS';

const getEditorDimensions = (format: string) => {
    if (format === "poster_a3") {
        return {
            w: POSTER_WIDTH_W_BLEED * POSTER_SCALE,
            h: POSTER_HEIGHT_W_BLEED * POSTER_SCALE,
            innerWidth: POSTER_WIDTH * POSTER_SCALE,
            innerHeight: POSTER_HEIGHT * POSTER_SCALE,
            dispScale: POSTER_SCALE,
            bleedX: POSTER_BLEED_X,
            bleedY: POSTER_BLEED_Y,
        }
    }

    // Card Defaults
    return {
        w: CARD_WIDTH_W_BLEED * SCALE,
        h: CARD_HEIGHT_W_BLEED * SCALE,
        innerWidth: CARD_WIDTH * SCALE,
        innerHeight: CARD_HEIGHT * SCALE,
        dispScale: SCALE,
        bleedX: CARD_BLEED_X,
        bleedY: CARD_BLEED_Y,
    }
}

export default getEditorDimensions;