import React from "react"
import { Outlet, Link, Router, Route, RootRoute } from "@tanstack/react-router"
import Home from "./Home"
import TileCreator from "./TileCreator"
import TemplateCreator from "../template_creator"

const rootRoute = new RootRoute({
    component: () => (
        <>
            <div className="p-2 flex gap-2">
                <Link to="/" disabled className="[&.active]:font-bold">
                    Starcardz
                </Link>
            </div>
            <hr />
            <Outlet />
        </>
    ),
})

const indexRoute = new Route({
    getParentRoute: () => rootRoute,
    path: "/orders/$orderId/demo_mode",
    component: function Index() {
        return <Home />
    },
})

const tileRoute = new Route({
    getParentRoute: () => rootRoute,
    path: "/orders/$orderId/demo_mode/tile_creator",
    component: () => <TileCreator />,
})

const templateCreator = new Route({
    getParentRoute: () => rootRoute,
    path: "/template_creator",
    component: () => <TemplateCreator />
})

const routeTree = rootRoute.addChildren([indexRoute, tileRoute, templateCreator])

const router = new Router({ routeTree })

declare module "@tanstack/react-router" {
    interface Register {
        router: typeof router
    }
}

export default router
