import React from "react"
import { w } from "../preview/CardSelection"


function ClearButton({onClick}) {
    return (
        <button className="p-2 bg-white" onClick={onClick}>
            <p style={{ width: w }}>Clear</p>
        </button>
    )
}

export default ClearButton