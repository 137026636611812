import React, { ReactNode, useEffect, useRef } from "react"
import { Group, Layer, Rect, Stage } from "react-konva"
import Toolbar from "./Toolbar"
import debounce from "lodash/debounce"
import { innerHeight, innerWidth } from "./layout"
import { useAtom } from "jotai"
import { selectedTextAtom } from "./atoms"
import TextToolbar from "./TextToolbar"
import useResetTextSelection from "../components/hooks/useResetTextSelection"
import CreateTemplateFooter from "./CreateTemplateFooter"

function Workspace({
    frontCard,
    backCard,
}: {
    children?: ReactNode
    frontCard?: ReactNode
    backCard?: ReactNode
}) {
    const resetTextSelection = useResetTextSelection()
    const containerRef = useRef()
    const stageRef = useRef()
    const layerRef = useRef()
    const layerRef2 = useRef()
    const bgRef = useRef()

    function handleClick(e) {
        const emptySpace = e.target === e.target.getStage()

        if (!emptySpace) {
            return
        }
        resetTextSelection()
    }

    function handleKeyUp(e) {
        if (e.code === "Escape") {
            resetTextSelection()
        }
    }

    useEffect(() => {
        function setContainerDimensions() {
            const nodeElement =
                containerRef?.current as unknown as HTMLDivElement

            const height = Math.max(nodeElement.offsetHeight, 500)
            const width = Math.max(nodeElement.offsetWidth, 500)
            const verticalCenter = (height - innerHeight) / 2
            const horizontalCenter = (width - innerWidth) / 2
            stageRef?.current.height(height)
            stageRef?.current.width(width)
            bgRef?.current.height(height)
            bgRef?.current.width(width)
            bgRef?.current.x(0)
            bgRef?.current.y(0)
            layerRef?.current.y(verticalCenter)

            if (!backCard) {
                layerRef?.current.x(horizontalCenter)
            } else {
                layerRef?.current.x(horizontalCenter - innerWidth / 1.75)
            }
            layerRef2?.current.y(verticalCenter)
            layerRef2?.current.x(horizontalCenter + innerWidth / 1.75)
        }

        const debouncedResize = debounce(setContainerDimensions, 400)

        const resizeObserver = new ResizeObserver(debouncedResize)
        resizeObserver.observe(containerRef?.current)

        return () => resizeObserver?.disconnect(containerRef?.current)
    }, [])

    return (
        <div
            ref={containerRef}
            className="relative w-full outline-0"
            style={{ height: "calc(100vh - 80px)" }}
            tabIndex={-1}
            onKeyUp={handleKeyUp}
        >
            <Stage ref={stageRef} className="d-block" onDblClick={handleClick}>
                <Layer>
                    <Rect
                        opacity={0.5}
                        fill="#e3e3e3"
                        ref={bgRef}
                        listening={false}
                    />
                    <Group ref={layerRef}>{frontCard}</Group>
                    <Group ref={layerRef2}>{backCard}</Group>
                </Layer>
            </Stage>
            <Toolbar/>
            <TextToolbar />
            <CreateTemplateFooter />
        </div>
    )
}

export default Workspace
