import { useAtom, useAtomValue } from "jotai"
import React from "react"
import { selectedTextAtom, selectedTextNodeAtom, textLayersAtom } from "./atoms"
import {
    AArrowDownIcon,
    AArrowUpIcon,
    AlignCenter,
    AlignLeft,
    AlignRight,
    BoldIcon,
    ItalicIcon,
} from "lucide-react"
import useFonts from "../components/hooks/useFonts"
import HeadlessCombobox from "./Combobox"
import ColorPicker from "../components/ColorPicker"
import { rotateViaCenter } from "../utils"

const WEBSAFE_FONTS = [
    { id: "arial", name: "Arial" },
    { id: "helvetica", name: "Helvetica" },
    { id: "tahoma", name: "Tahoma" },
    { id: "trebuchet-ms", name: "Trebuchet MS" },
    { id: "verdana", name: "Verdana" },
    { id: "georgia", name: "Georgia" },
    { id: "times-new-roman", name: "Times New Roman" },
    { id: "palatino", name: "Palatino" },
    { id: "courier-new", name: "Courier New" },
    { id: "lucida-console", name: "Lucida Console" },
    { id: "impact", name: "Impact" },
    { id: "arial-black", name: "Arial Black" },
]

function ToolbarLabel({ children }) {
    return (
        <span className="w-full text-xs px-1 py-0.5 font-bold uppercase">
            {children}
        </span>
    )
}

const TextAlignmentOptions = [
    { value: "left", icon: <AlignLeft /> },
    { value: "center", icon: <AlignCenter /> },
    { value: "right", icon: <AlignRight /> },
]

const VTextAlignmentOptions = [
    {
        value: "top",
        icon: <AlignCenter />,
        className: "justify-start items-center",
    },
    {
        value: "middle",
        icon: <AlignCenter />,
        className: "justify-center items-center",
    },
    {
        value: "bottom",
        icon: <AlignCenter />,
        className: "justify-end items-center",
    },
]

const FontStyleOptions = [
    {
        value: "italic",
        icon: <ItalicIcon />,
    },
    {
        value: "bold",
        icon: <BoldIcon />,
    },
]

function TextToolbar() {
    const { fonts } = useFonts()
    const [selectedText] = useAtom(selectedTextAtom)
    const selectedNode = useAtomValue(selectedTextNodeAtom)
    const [textLayers, setTextLayers] = useAtom(textLayersAtom)

    const text = textLayers.find((t) => t.id === selectedText)
    function updateRotation(e) {
        const node = selectedNode as unknown as Node
        const { x, y, rotation } = rotateViaCenter(e, node)

        const newTextLayers = textLayers.map((t) => {
            if (t.id === selectedText) {
                return {
                    ...t,
                    x,
                    y,
                    rotation,
                }
            } else {
                return t
            }
        })
        setTextLayers(newTextLayers)
    }

    function updatePosition(position: string, e) {
        const newValue =
            typeof e.target.value === "string" ? Number(e.target.value) : ""
        const newTextLayers = textLayers.map((t) => {
            if (t.id === selectedText) {
                return {
                    ...t,
                    [position]: newValue,
                }
            } else {
                return t
            }
        })
        setTextLayers(newTextLayers)
    }

    function updateSkew(position: string, e) {
        const newValue =
            typeof e.target.value === "string" ? Number(e.target.value) : ""
        const newTextLayers = textLayers.map((t) => {
            if (t.id === selectedText) {
                return {
                    ...t,
                    skew: {
                        ...t.skew,
                        [position]: newValue,
                    },
                }
            } else {
                return t
            }
        })
        setTextLayers(newTextLayers)
    }

    function updateText(e) {
        const newText = e.target.value
        const newTextLayers = textLayers.map((t) => {
            if (t.id === selectedText) {
                return {
                    ...t,
                    text: newText,
                }
            } else {
                return t
            }
        })
        setTextLayers(newTextLayers)
    }

    function updateFontSize(e) {
        const newText = e.target.value
        const newTextLayers = textLayers.map((t) => {
            if (t.id === selectedText) {
                return {
                    ...t,
                    fontSize: isNaN(newText) ? newText : Number(newText),
                }
            } else {
                return t
            }
        })
        setTextLayers(newTextLayers)
    }

    function updateFontSizeByOne(n) {
        const newTextLayers = textLayers.map((t) => {
            if (t.id === selectedText) {
                return {
                    ...t,
                    fontSize: Number(t.fontSize) + n,
                }
            } else {
                return t
            }
        })
        setTextLayers(newTextLayers)
    }

    function updateTextAlignment(align) {
        const newTextLayers = textLayers.map((t) => {
            if (t.id === selectedText) {
                return {
                    ...t,
                    align,
                }
            } else {
                return t
            }
        })
        setTextLayers(newTextLayers)
    }

    function updateLetterSpacing(e) {
        const newTextLayers = textLayers.map((t) => {
            if (t.id === selectedText) {
                return {
                    ...t,
                    letterSpacing: isNaN(e.target.value)
                        ? e.target.value
                        : Number(e.target.value),
                }
            } else {
                return t
            }
        })
        setTextLayers(newTextLayers)
    }

    function updateVerticalAlignment(verticalAlign) {
        const newTextLayers = textLayers.map((t) => {
            if (t.id === selectedText) {
                return {
                    ...t,
                    verticalAlign,
                }
            } else {
                return t
            }
        })
        setTextLayers(newTextLayers)
    }

    function updateFontFamily(fontFamily) {
        const newTextLayers = textLayers.map((t) => {
            if (t.id === selectedText) {
                return {
                    ...t,
                    fontFamily,
                }
            } else {
                return t
            }
        })
        setTextLayers(newTextLayers)
    }

    function updateTextColor(color) {
        const newTextLayers = textLayers.map((t) => {
            if (t.id === selectedText) {
                return {
                    ...t,
                    fill: color.hexa,
                }
            } else {
                return t
            }
        })
        setTextLayers(newTextLayers)
    }

    function updateFontStyle(v) {
        const currentStyle = textLayers.find((t) => t.id === selectedText)
        const values: string[] = currentStyle?.fontStyle?.split(" ") ?? []

        let newFontStyle: string[] = []
        const isPresent = values.includes(v)
        const isNormal = values.includes("normal")
        if (isPresent) {
            newFontStyle = values.filter((s) => s !== v && s !== "normal")
        } else {
            newFontStyle = isNormal ? [].concat(v) : values.concat(v)
        }

        const fontStyle = newFontStyle.join(" ").trim()
        const finalFontStyle = fontStyle.length > 0 ? fontStyle : "normal"

        const newTextLayers = textLayers.map((t) => {
            if (t.id === selectedText) {
                return {
                    ...t,
                    fontStyle: finalFontStyle,
                }
            } else {
                return t
            }
        })
        setTextLayers(newTextLayers)
    }

    if (!text) {
        return null
    }

    return (
        <div className="fixed top-[40px] right-0 left-12 bg-slate-50 items-center shadow-sm z-10 flex gap-x-2 divide-x">
            <div className="p-1">
                <ToolbarLabel>Rotation</ToolbarLabel>
                <span className="h-full flex flex-col  flex items-center justify-center relative">
                    <input
                        type="number"
                        value={text.rotation}
                        onChange={updateRotation}
                        step={0.1}
                        className="px-2 py-0.5 h-8 w-24"
                    />
                </span>
            </div>
            <div className="p-1">
                <ToolbarLabel>Position</ToolbarLabel>
                <div className="flex items-center">
                    <span className="h-full flex   flex items-center justify-center relative">
                        <ToolbarLabel>X</ToolbarLabel>
                        <input
                            type="number"
                            value={Number(text.x).toFixed(2)}
                            onChange={updatePosition.bind(null, "x")}
                            step={0.1}
                            className="px-2 py-0.5 h-8 w-24"
                        />
                    </span>
                    <span className="h-full flex   flex items-center justify-center relative">
                        <ToolbarLabel>Y</ToolbarLabel>
                        <input
                            type="number"
                            value={Number(text.y).toFixed(2)}
                            onChange={updatePosition.bind(null, "y")}
                            step={0.1}
                            className="px-2 py-0.5 h-8 w-24"
                        />
                    </span>
                </div>
            </div>
            <div className="p-1">
                <ToolbarLabel>Skew</ToolbarLabel>
                <div className="flex items-center">
                    <span className="h-full flex  flex items-center relative">
                        <ToolbarLabel>X</ToolbarLabel>
                        <input
                            type="number"
                            value={Number(text.skew.x).toFixed(2)}
                            onChange={updateSkew.bind(null, "x")}
                            step={0.01}
                            className="px-2 py-0.5 h-8 w-24"
                        />
                    </span>
                    <span className="h-full flex  flex items-center relative">
                        <ToolbarLabel>Y</ToolbarLabel>
                        <input
                            type="number"
                            value={Number(text.skew.y).toFixed(2)}
                            onChange={updateSkew.bind(null, "y")}
                            step={0.01}
                            className="px-2 py-0.5 h-8 w-24"
                        />
                    </span>
                </div>
            </div>
            <div className="p-1">
                <span className="h-full flex flex-col  flex items-center justify-center relative">
                    <ToolbarLabel>Value</ToolbarLabel>
                    <input
                        type="text"
                        value={text.text}
                        onChange={updateText}
                        className="mt-auto px-2 py-0.5 h-8"
                    />
                </span>
            </div>
            <div className="p-1">
                <span className="h-full flex flex-col  flex items-center justify-center relative">
                    <ToolbarLabel>Font Size</ToolbarLabel>
                    <span className="mt-auto flex items-center">
                        <button
                            className="px-0.5"
                            onClick={() => updateFontSizeByOne(-1)}
                        >
                            <AArrowDownIcon />
                        </button>
                        <input
                            type="number"
                            value={text.fontSize}
                            onChange={updateFontSize}
                            className="w-12 text-center h-8"
                        />
                        <button
                            className="px-0.5"
                            onClick={() => updateFontSizeByOne(1)}
                        >
                            <AArrowUpIcon />
                        </button>
                    </span>
                </span>
            </div>
            <div className="p-1">
                <span className="h-full flex flex-col  flex items-center justify-center relative">
                    <ToolbarLabel>Text Align</ToolbarLabel>
                    <span className="mt-auto flex items-center">
                        {TextAlignmentOptions.map((t, i) => (
                            <button
                                className={`px-0.5 h-8 w-8 border ${
                                    t.value === text.align ? "bg-slate-200" : ""
                                }`}
                                key={i}
                                onClick={() => updateTextAlignment(t.value)}
                            >
                                {t.icon}
                            </button>
                        ))}
                    </span>
                </span>
            </div>
            <div className="p-1">
                <span className="h-full flex flex-col  flex items-center justify-center relative">
                    <ToolbarLabel>Vertical&nbsp;Align</ToolbarLabel>
                    <span className="mt-auto flex items-center">
                        {VTextAlignmentOptions.map((t, i) => (
                            <button
                                className={`px-0.5 h-8 w-8 flex flex-col border ${
                                    t.value === text.verticalAlign
                                        ? "bg-slate-200"
                                        : ""
                                } ${t.className}`}
                                key={i}
                                onClick={() => updateVerticalAlignment(t.value)}
                            >
                                {t.icon}
                            </button>
                        ))}
                    </span>
                </span>
            </div>
            <div className="p-1">
                <span className="h-full flex flex-col  flex items-center justify-center relative">
                    <ToolbarLabel>Letter&nbsp;Spacing</ToolbarLabel>
                    <span className="mt-auto flex items-center">
                        <input
                            type="number"
                            value={text.letterSpacing}
                            onChange={updateLetterSpacing}
                            className="text-center h-8 w-12"
                            step={0.1}
                        />
                    </span>
                </span>
            </div>
            <div className="p-1">
                <span className="h-full flex flex-col  flex items-center justify-center relative">
                    <ToolbarLabel>Font&nbsp;Style</ToolbarLabel>
                    <span className="mt-auto flex items-center">
                        {FontStyleOptions.map((t, i) => (
                            <button
                                className={`px-0.5 h-8 w-8 border ${
                                    text.fontStyle?.includes(t.value)
                                        ? "bg-slate-200"
                                        : ""
                                }`}
                                key={i}
                                onClick={() => updateFontStyle(t.value)}
                            >
                                {t.icon}
                            </button>
                        ))}
                    </span>
                </span>
            </div>
            <div className="p-1">
                <span className="h-full flex flex-col  flex items-center justify-center relative">
                    <ToolbarLabel>Color</ToolbarLabel>
                    <span className="mt-auto flex items-center h-8">
                        <ColorPicker
                            onChange={updateTextColor}
                            color={text.fill ?? "black"}
                            showLabel={false}
                        />
                    </span>
                </span>
            </div>
            <div className="p-1">
                <span className="h-full flex flex-col  flex items-center justify-center relative">
                    <ToolbarLabel>Font Family</ToolbarLabel>
                    <HeadlessCombobox
                        selected={text.fontFamily}
                        setSelected={updateFontFamily}
                        options={WEBSAFE_FONTS.concat(fonts)}
                    />
                </span>
            </div>
        </div>
    )
}

export default TextToolbar
