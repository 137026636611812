import React, { useState } from "react"
import { useAtom } from "jotai"
import { selectedBgAtom } from "../atoms"
import { CardTemplate } from "../../preview/CardSelection"
import { ImageIcon } from "lucide-react"
import ClearButton from "../../components/ClearButton"
import useResetTextSelection from "../../components/hooks/useResetTextSelection"
import PopoverContainer from "./PopoverContainer"

function BackgroundSelection({ backgrounds = [] }) {
    const resetTextSelection = useResetTextSelection()
    const [selectedBg, setSelectedBg] = useAtom(selectedBgAtom)
    const [query, setQuery] = useState("")

    return (
        <PopoverContainer
            title="Background"
            icon={<ImageIcon />}
            onButtonClick={resetTextSelection}
            label="Select Background"
            query={query}
            setQuery={setQuery}
        >
            {backgrounds
                ?.filter((bg) =>
                    bg?.name.toLowerCase().includes(query.toLowerCase())
                )
                .map((bg) => (
                    <CardTemplate
                        key={bg.id}
                        background_image={bg.image}
                        selectTemplate={() => setSelectedBg(bg)}
                        isSelected={selectedBg?.id === bg.id}
                        title={bg.name}
                    />
                ))}
            {selectedBg && <ClearButton onClick={() => setSelectedBg(null)} />}
        </PopoverContainer>
    )
}

export default BackgroundSelection
