import { atom } from "jotai"
import { atomWithStorage, createJSONStorage } from "jotai/utils"
import {
    CARD_BLEED_X,
    CARD_BLEED_Y,
    CARD_HEIGHT_W_BLEED,
    CARD_WIDTH_W_BLEED,
    TEXT_LAYER,
} from "../utils/CONSTANTS"
import { SCALE } from "./layout"
import Konva from "konva"

export interface TextConfig {
    text?: string
    fontFamily?: string
    fontSize?: number
    fontStyle?: string
    fontVariant?: string
    textDecoration?: string
    align?: "left" | "center" | "right"
    verticalAlign?: "top" | "middle" | "bottom"
    lineHeight?: number
    letterSpacing?: number
    wrap?: boolean
    ellipsis?: boolean
    x?: number
    y?: number
    rotation?: number
    width?: number
    height?: number
    fill?: string
    skew: {
        x: number
        y: number
    }
}

export interface TextLayerConfig extends TextConfig {
    id?: string
    canDelete?: boolean
    hidden?: boolean
}

const FIRST_LINE = {
    ...TEXT_LAYER,
    text: "First Line",
    id: "first-line",
    canDelete: false,
    hidden: false,
    x: 0,
    y: 0,
    wrap: true,
}
const SECOND_LINE = {
    ...TEXT_LAYER,
    text: "Second Line",
    id: "second-line",
    hidden: false,
    canDelete: false,
    x: CARD_WIDTH_W_BLEED * 0.5 * SCALE,
    y: CARD_HEIGHT_W_BLEED * 0.5 * SCALE,
}
const SIGNATURE_LINE = {
    ...TEXT_LAYER,
    text: "Signature",
    id: "signature-line",
    canDelete: false,
    hidden: false,
    x: CARD_WIDTH_W_BLEED * 0.5 * SCALE - 20,
    y: CARD_HEIGHT_W_BLEED * 0.5 * SCALE - 20,
    fontSize: 16,
}
const YEAR_LINE: TextLayerConfig = {
    ...TEXT_LAYER,
    text: "Year",
    id: "year-line",
    canDelete: false,
    hidden: false,
    fontSize: 16,
    x: (CARD_WIDTH_W_BLEED - CARD_BLEED_X) * SCALE - 40,
    y: CARD_BLEED_Y * SCALE,
    align: "right",
}

export const textLayersAtom = atom([
    FIRST_LINE,
    SECOND_LINE,
    SIGNATURE_LINE,
    YEAR_LINE,
])
interface ImageConfigT {
    id: string
    name: string
    image: string
}
export const selectedTextAtom = atom<string | null>(null)
export const selectedTextNodeAtom = atom<Konva.Text | null>(null)
export const selectedBgAtom = atom<ImageConfigT | null>(null)
export const selectedBackImageAtom = atom<ImageConfigT | null>(null)
export const selectedFrontImageAtom = atom<ImageConfigT | null>(null)

const storage = createJSONStorage(() => sessionStorage)
export const sessionAtom = atomWithStorage("session-draft", {}, storage)
export const localstorageAtom = atomWithStorage("localstorage-draft", {})
