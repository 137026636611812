import React, { useRef, useState } from "react"
import { Image, Layer, Stage } from "react-konva"
import BackgroundImage from "../components/BackgroundImage"
import { Link } from "@tanstack/react-router"

import useImage from "use-image"
import { ArrowLeft, Download } from "lucide-react"
import downloadURI from "./utils/downloadURI"

function Card({
    src,
    width,
    height,
    x,
    y,
}: {
    src: string
    width: number
    height: number
    x: number
    y: number
}) {
    const imageRef = useRef()
    const [image] = useImage(src, "anonymous")

    if (!image) {
        return null
    }

    return (
        <Image
            ref={imageRef}
            image={image}
            width={width}
            height={height}
            x={x}
            y={y}
        />
    )
}

function SocialTileView({
    image,
    width,
    height,
    cardName,
    x,
    y,
    displayBack = true,
    stageWidth = 650,
    stageHeight = 650,
    clipFunc,
    pixelRatio = 1,
}) {
    const [filename, setFilename] = useState(cardName ?? "player-tile")
    const stageRef = useRef()
    function downloadTile() {
        const dataURL = stageRef?.current?.toDataURL({
            pixelRatio: 1 / pixelRatio,
            mimeType: `image/png`,
        })
        downloadURI(
            dataURL,
            `${filename.toLowerCase().split(" ").join("-")}.png`
        )
    }
    return (
        <>
            <Stage width={stageWidth} height={stageHeight} ref={stageRef}>
                <Layer>
                    <BackgroundImage
                        src="/starcardz-digital-template.jpg"
                        width={stageWidth}
                        height={stageHeight}
                    />
                </Layer>
                <Layer clipFunc={clipFunc} x={x} y={y}>
                    <Card
                        src={image}
                        width={width}
                        height={height}
                        x={0}
                        y={0}
                    />
                </Layer>
            </Stage>
            <div className="flex items-center gap-4 pt-4 w-full max-w-[650px]">
                {displayBack && (
                    <Link
                        to="../"
                        className="flex items-center hover:text-primary mr-auto"
                        state={{
                            image: null,
                            width: null,
                            height: null,
                        }}
                    >
                        <ArrowLeft />
                        <span className="ml-1">Back</span>
                    </Link>
                )}
                <input
                    type="text"
                    value={filename}
                    className="!border !rounded-md !p-2 !bg-white"
                    onChange={(e) => setFilename(e.target.value)}
                />
                <button
                    className="!h-12 px-4 !flex !items-center !hover:text-primary !border-0 !bg-white !hover:bg-white"
                    onClick={downloadTile}
                    type="button"
                >
                    <Download className="h-6 w-6" />
                    <span className="ml-2">Download Tile</span>
                </button>
            </div>
        </>
    )
}

export default SocialTileView
