import React, { useState } from "react"
import { useAtom } from "jotai"
import { selectedFrontImageAtom } from "../atoms"
import { CardTemplate } from "../../preview/CardSelection"
import { BookImageIcon } from "lucide-react"
import ClearButton from "../../components/ClearButton"
import useResetTextSelection from "../../components/hooks/useResetTextSelection"
import PopoverContainer from "./PopoverContainer"
import UploadImage from "./UploadImage"

function FrontImageSelection() {
    const [frontImages, setFrontImages] = useState([])
    const resetTextSelection = useResetTextSelection()
    const [selectedBg, setSelectedBg] = useAtom(selectedFrontImageAtom)
    const [query, setQuery] = useState("")

    return (
        <PopoverContainer
            title="Front Image"
            icon={<BookImageIcon />}
            onButtonClick={resetTextSelection}
            label="Select Foreground"
            query={query}
            setQuery={setQuery}
        >
            {frontImages
                ?.filter((bg) =>
                    bg?.name.toLowerCase().includes(query.toLowerCase())
                )
                .map((bg) => (
                    <div className="flex flex-col" key={bg.id}>
                        <CardTemplate
                            background_image={bg.image}
                            selectTemplate={() => setSelectedBg(bg)}
                            isSelected={selectedBg?.id === bg.id}
                            title={bg.name}
                        />
                        <button
                            className="text-red-500 border-red p-2 rounded-md"
                            onClick={() =>
                                setFrontImages((prevFiles) =>
                                    prevFiles.filter((f) => f.id !== bg.id)
                                )
                            }
                        >
                            Remove
                        </button>
                    </div>
                ))}
            {selectedBg && <ClearButton onClick={() => setSelectedBg(null)} />}
            <UploadImage
                label="Upload front images"
                id="front-images"
                setImages={setFrontImages}
            />
        </PopoverContainer>
    )
}

export default FrontImageSelection
