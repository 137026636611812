import { TextConfig } from "../template_creator/atoms"
// Card Defaults
export const CARD_HEIGHT = 1051
export const CARD_WIDTH = 756
export const CARD_BLEED_X = 35.5
export const CARD_BLEED_Y = 35.5
export const SCALE = 0.5
export const CARD_WIDTH_W_BLEED = CARD_WIDTH + CARD_BLEED_X * 2
export const CARD_HEIGHT_W_BLEED = CARD_HEIGHT + CARD_BLEED_Y * 2

// Poster Defaults
export const POSTER_HEIGHT = 4961
export const POSTER_WIDTH = 3508
export const POSTER_BLEED_X = 59
export const POSTER_BLEED_Y = 59
export const POSTER_SCALE = 0.1
export const POSTER_WIDTH_W_BLEED = POSTER_WIDTH + POSTER_BLEED_X * 2
export const POSTER_HEIGHT_W_BLEED = POSTER_HEIGHT + POSTER_BLEED_Y * 2

export const MIN_SCALE = 0.1
export const MAX_SCALE = 3
export const SCALE_STEP = 0.01
export const MIN_DEG = -180
export const MAX_DEG = 180
export const ROTATION_SNAPS = [-180, -135, -90, -45, 0, 45, 90, 135, 180]

export const TEXT_LAYER: TextConfig = {
    text: "Sample",
    fontFamily: "sans-serif",
    fontSize: 24,
    fill: 'black',
    align: "center",
    verticalAlign: "middle",
    x: CARD_BLEED_X * SCALE,
    y: CARD_BLEED_Y * SCALE,
    rotation: 0,
    letterSpacing: 0,
    skew: {
        x: 0,
        y: 0
    }
}
