import React, { Suspense, StrictMode } from "react"
import { createRoot } from "react-dom/client"
import "../stylesheet/index.css"
import Konva from "konva"
import SocialTileView from "../preview/SocialTileView"
import {
    CARD_BLEED_X,
    CARD_BLEED_Y,
    CARD_HEIGHT,
    CARD_HEIGHT_W_BLEED,
    CARD_WIDTH,
    CARD_WIDTH_W_BLEED,
} from "../utils/CONSTANTS"
import { clipSquare } from "../utils"

Konva.hitOnDragEnabled = true
Konva.captureTouchEventsEnabled = true

const EL = ".js--socTileComponent"
const STAGE_WIDTH = 650
const NEW_STAGE_WIDTH = 300
const ASPECT_RATIO = NEW_STAGE_WIDTH / STAGE_WIDTH
const containers = document.querySelectorAll(EL)
const SCALE = 0.5
const innerWidth = CARD_WIDTH * SCALE
const innerHeight = CARD_HEIGHT * SCALE
const PIXEL_RATIO = NEW_STAGE_WIDTH / 812

function App({ image, cardName }) {
    function clipFunc(ctx) {
        const x = CARD_BLEED_X * SCALE * ASPECT_RATIO
        const y = CARD_BLEED_Y * SCALE * ASPECT_RATIO
        clipSquare(ctx, x, y, innerWidth * ASPECT_RATIO, innerHeight * ASPECT_RATIO, 16 * ASPECT_RATIO)
    }
    return (
        <SocialTileView
            image={image}
            width={CARD_WIDTH_W_BLEED * SCALE * ASPECT_RATIO}
            height={CARD_HEIGHT_W_BLEED * SCALE * ASPECT_RATIO}
            x={200 * ASPECT_RATIO}
            y={46 * ASPECT_RATIO}
            displayBack={false}
            clipFunc={clipFunc}
            stageWidth={NEW_STAGE_WIDTH}
            stageHeight={NEW_STAGE_WIDTH}
            pixelRatio={PIXEL_RATIO}
            cardName={cardName}
        />
    )
}

if (containers?.length > 0) {
    Array.from(containers).forEach((container) => {
        const root = createRoot(container!)
        const image = container.dataset.frontPreviewPath
        const cardName = container.dataset.cardName
        root.render(
            <StrictMode>
                <Suspense fallback={<p>Loading</p>}>
                    <App image={image} cardName={cardName}/>
                </Suspense>
            </StrictMode>
        )
    })
}
