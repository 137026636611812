import React, {
    SetStateAction,
    Dispatch,
    useState,
    useEffect,
    useRef,
} from "react"
import { TextConfigT, LogoConfigT } from "../utils"
import {
    CARD_HEIGHT,
    CARD_HEIGHT_W_BLEED,
    CARD_WIDTH,
    CARD_WIDTH_W_BLEED,
} from "../utils/CONSTANTS"
import { Search } from "lucide-react"

export interface TCardTemplate {
    id: string
    template_name: string
    name_position: TextConfigT
    year_position: TextConfigT
    logo_position: LogoConfigT
    brand_logo_position: LogoConfigT
    signature_position: TextConfigT
    tag_image_position: LogoConfigT
    secondary_line_position: TextConfigT
    default_background_id: string
    front_image: string
    back_image: string
    brand_logo_image: string
    default_card_logo_image: string | null
    default_club_logo_id: string | null
    tag_image: string | null
    title?: string
}

const SCALE = 0.05
const h = CARD_HEIGHT_W_BLEED * SCALE
export const w = CARD_WIDTH_W_BLEED * SCALE
const innerWidth = CARD_WIDTH * SCALE
const innerHeight = CARD_HEIGHT * SCALE

export function CardTemplate({
    template_name,
    front_image,
    isSelected,
    selectTemplate,
    background_image,
    brand_logo_image,
    brand_logo_position,
    default_card_logo_image,
    logo_position,
    title,
}) {
    const cx = [
        "flex flex-col items-center justify-center",
        "rounded-md p-2",
        isSelected
            ? "border-2 border-primary bg-primary text-white"
            : "border-2 border-transparent bg-transparent",
    ]
        .filter(Boolean)
        .join(" ")
    return (
        <button
            type="button"
            onClick={selectTemplate}
            className={cx}
            title={title}
        >
            {template_name && <span>{template_name}</span>}
            <div
                className="relative overflow-hidden flex items-center justify-center"
                style={{ height: h, width: w }}
            >
                <div
                    className="absolute overflow-hidden"
                    style={{
                        height: innerHeight,
                        width: innerWidth,
                        borderRadius: "calc(16px * 0.125)",
                    }}
                >
                    {background_image && (
                        <div
                            className="absolute inset-0 bg-cover bg-no-repeat"
                            style={{
                                backgroundImage: `url(${background_image})`,
                            }}
                        />
                    )}
                    {front_image && (
                        <div
                            className="absolute inset-0 bg-cover bg-no-repeat z-10"
                            style={{
                                backgroundImage: `url(${front_image})`,
                            }}
                        />
                    )}
                    {brand_logo_image && (
                        <img
                            src={brand_logo_image}
                            className="absolute"
                            style={{
                                height: brand_logo_position.height,
                                top: (brand_logo_position.y / 100) * h,
                                left: (brand_logo_position.x / 100) * w,
                            }}
                        />
                    )}
                    {default_card_logo_image && (
                        <img
                            src={default_card_logo_image}
                            className="absolute"
                            style={{
                                height: logo_position.height,
                                top: (logo_position.y / 100) * h,
                                left: (logo_position.x / 100) * w,
                            }}
                        />
                    )}
                </div>
            </div>
        </button>
    )
}

function CardTemplateSelection({
    templates,
    setTemplate,
    selected,
}: {
    templates: TCardTemplate[]
    setTemplate: Dispatch<SetStateAction<string>>
    selected: string | null
}) {
    const ref = useRef()
    const [displayTemplates, setDisplayTemplates] = useState(false)
    const [search, setSearch] = useState("")

    useEffect(() => {
        function onClickOutside(e) {
            if (!(ref.current as HTMLElement).contains(e.target)) {
                setDisplayTemplates(false)
            }
        }

        document.addEventListener("click", onClickOutside)

        return () => document.removeEventListener("click", onClickOutside)
    }, [])

    const cx = [
        "border flex items-center px-4 py-2 rounded-md bg-white",
        displayTemplates ? "border-b-0 rounded-b-none z-20" : "",
    ].join(" ")

    return (
        <div className="flex flex-col gap-2 relative bg-white" ref={ref}>
            <button
                className={cx}
                type="button"
                onClick={() => setDisplayTemplates(!displayTemplates)}
            >
                Templates
            </button>
            {displayTemplates && (
                <div className="z-10 max-h-[600px] overflow-auto grid grid-cols-3 gap-2 min-w-[400px] w-[400px] absolute top-10 bg-white border shadow-md rounded-b-md rounded-r-md p-4">
                    <div className="col-span-full flex items-stretch">
                        <label
                            htmlFor="searchTemplates"
                            className="flex items-center py-2 px-4 border rounded-l-md"
                        >
                            <Search className="w-4 h-4" />
                        </label>
                        <input
                            className="border-r border-y px-3 py-2 rounded-r-md w-full"
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            id="searchTemplates"
                            placeholder="Search"
                        />
                    </div>
                    {templates
                        .filter((template) =>
                            Boolean(search)
                                ? template?.template_name
                                      ?.toLowerCase()
                                      ?.includes(search?.toLowerCase())
                                : true
                        )
                        .map((t, i: number) => (
                            <CardTemplate
                                {...t}
                                key={i}
                                isSelected={selected === t.id}
                                selectTemplate={() => setTemplate(t.id)}
                            />
                        ))}
                </div>
            )}
        </div>
    )
}

export default CardTemplateSelection
