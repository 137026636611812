import React, { useEffect, useRef, useState } from "react"
import { Stage, Layer, Group, Rect } from "react-konva"
import BackgroundImage from "../components/BackgroundImage"
import PlayerImage from "../components/PlayerImage"
import ForegroundImage from "../components/ForegroundImage"
import ImageLoader from "../components/ImageLoader"
import TextContainer from "../components/TextContainer"
import {
    LogoConfigT,
    TextConfigT,
    clipSquare,
    rotateViaCenter,
    transformFormDataForAPI,
    transformTextConfig,
} from "../utils"
import EditCardDetails from "./EditCardDetails"
import { Download, Edit } from "lucide-react"
import { useNavigate, useParams } from "@tanstack/react-router"
import downloadURI from "./utils/downloadURI"
import { useMutation } from "react-query"
import getEditorDimensions from "../utils/getEditorDimensions"

const wait = (milliseconds) =>
    new Promise((resolve) => setTimeout(resolve, milliseconds))

type TPersonImageMeta =
    | undefined
    | {
          width: number
          height: number
          playerId: string
      }

function Display({
    backgroundId,
    background,
    format = "card",
    frontImage,
    personImage,
    logoImage,
    brandLogoImage,
    personImageMeta,
    imageConfig,
    logoConfig,
    brandConfig,
    nameConfig,
    signatureConfig,
    yearConfig,
    playerName,
    cardYear,
    cardSignature,
    secondaryLine,
    secondaryLineConfig,
    rotation,
    setRotation,
    scale,
    setScale,
    points,
    setPoints,
    templateId,
    clubLogoId,
    landscape = false,
    sportLogoId,
}: {
    background: string | undefined
    personImage?: string
    format:string
    frontImage: string
    logoImage: string
    brandLogoImage: string
    personImageMeta?: TPersonImageMeta
    imageConfig?: any
    logoConfig: LogoConfigT
    brandConfig: LogoConfigT
    nameConfig: TextConfigT
    signatureConfig: TextConfigT
    yearConfig: TextConfigT
    secondaryLineConfig: TextConfigT
    playerName: string
    cardYear: string
    cardSignature: string
    secondaryLine: string
    rotation: number
    setRotation: any
    scale: number
    setScale: any
    points: {
        x: number
        y: number
    }
    setPoints: any
    templateId?: string
    clubLogoId?: string | null
    landscape: boolean
    sportLogoId?: string | null
}) {
    const navigate = useNavigate({ from: "/orders/$orderId/demo_mode" })
    const [showEditor, setShowEditor] = useState(false)
    const [textDataURL, setTextDataURL] = useState(null)
    const stageRef = useRef()
    const shapeRef = useRef()
    const imageGroupRef = useRef()
    const shadowRef = useRef()
    const logoRef = useRef()
    const brandRef = useRef()
    const orderId = useParams({
        from: "/orders/$orderId/demo_mode",
        select: (p) => p.orderId,
    })
    const { mutate, isLoading } = useMutation({
        mutationFn: async ({ orderId, ...card }) => {
            const res = await fetch(`/api/orders/${orderId}/order_cards`, {
                method: "POST",
                headers: { "Content-type": "application/json; charset=UTF-8" },
                body: JSON.stringify(card),
            })
            const data = await res.json()
            return data
        },
    })

    const {
        dispScale,
        w,
        h,
        innerWidth,
        innerHeight,
        bleedX,
        bleedY,
    }= getEditorDimensions(format)

    const pw = personImageMeta?.width! * dispScale
    const ph = personImageMeta?.height! * dispScale

    // Player Config
    const [isSelected, setIsSelected] = useState(false)

    const [nameFontSize, setNameFontSize] = useState(nameConfig?.font_size)
    const [cardSignatureFontSize, setCardSignatureFontSize] = useState(
        signatureConfig?.font_size
    )
    const [yearFontSize, setYearFontSize] = useState(yearConfig?.font_size)
    const [secondaryLineFontSize, setSecondaryLineFontSize] = useState(
        secondaryLineConfig?.font_size
    )

    // Name Config
    const [nameLetterSpacing, setNameLetterSpacing] = useState(
        nameConfig?.letter_spacing || 0
    )
    const [nameSkew, setNameSkew] = useState({
        x: nameConfig?.skew_x || 0,
        y: nameConfig?.skew_y || 0,
    })
    const [nameRotation, setNameRotation] = useState(nameConfig?.rotation || 0)
    const [nameColor, setNameColor] = useState(nameConfig.font_color)

    // Signature Config

    const [signatureLetterSpacing, setSignatureLetterSpacing] = useState(
        signatureConfig?.letter_spacing || 0
    )
    const [signatureSkew, setSignatureSkew] = useState({
        x: signatureConfig?.skew_x || 0,
        y: signatureConfig?.skew_y || 0,
    })
    const [signatureRotation, setSignatureRotation] = useState(
        signatureConfig?.rotation || 0
    )
    const [signatureColor, setSignatureColor] = useState(
        signatureConfig.font_color
    )

    // Year Config
    const [yearLetterSpacing, setYearLetterSpacing] = useState(
        yearConfig?.letter_spacing || 0
    )
    const [yearSkew, setYearSkew] = useState({
        x: yearConfig?.skew_x || 0,
        y: yearConfig?.skew_y || 0,
    })
    const [yearRotation, setYearRotation] = useState(yearConfig?.rotation || 0)
    const [yearColor, setYearColor] = useState(yearConfig.font_color)

    // Secondary Line Config
    const [secondaryLineLetterSpacing, setSecondaryLineLetterSpacing] =
        useState(secondaryLineConfig?.letter_spacing || 0)
    const [secondaryLineSkew, setSecondaryLineSkew] = useState({
        x: secondaryLineConfig.skew_x || 0,
        y: secondaryLineConfig.skew_y || 0,
    })
    const [secondaryLineRotation, setSecondaryLineRotation] = useState(
        secondaryLineConfig.rotation || 0
    )
    const [secondaryLineColor, setSecondaryLineColor] = useState(
        secondaryLineConfig.font_color
    )

    const [brandLogoConfig, setBrandLogoConfig] = useState(brandConfig)
    const [teamLogoConfig, setTeamLogoConfig] = useState(logoConfig)
    const cardContainer = [
        "relative overflow-hidden transition origin-center flex items-center justify-center",
        landscape ? "rotate-90" : "",
    ].join(" ")

    function onSelect(e) {
        const emptySpace = e.target === e.target.getStage()
        if (!emptySpace) {
            setIsSelected((s) => !s)
        }
    }

    function deselect() {
        setIsSelected(false)
    }

    function rotate(e) {
        const node = shapeRef?.current as unknown as Node
        const { x, y, rotation } = rotateViaCenter(e, node)
        setPoints({ x, y })
        setRotation(rotation)
    }

    function rotateLogo(e) {
        const node = logoRef?.current as unknown as Node
        const { x: _x, y: _y, rotation } = rotateViaCenter(e, node)
        const x = _x * 100 / w
        const y = _y * 100 / h
        setTeamLogoConfig((c) => ({ ...c, x, y, rotation }))
    }

    function rotateBrand(e) {
        const node = brandRef?.current as unknown as Node
        const { x: _x, y: _y, rotation } = rotateViaCenter(e, node)
        const x = _x * 100 / w
        const y = _y * 100 / h
        setBrandLogoConfig((c) => ({ ...c, x, y, rotation }))
    }

    async function downloadImage() {
        if (isSelected) {
            setIsSelected(false)
            await wait(1000)
        }
        const dataURL = stageRef?.current?.toDataURL({
            pixelRatio: 1 / dispScale,
            mimeType: `image/png`,
        })
        downloadURI(
            dataURL,
            `${playerName.split(" ").join("-").toLowerCase()}.png`
        )
    }

    function goToTileCreator() {
        shadowRef.current?.hide()
        const dataURL = stageRef?.current?.toDataURL({
            pixelRatio: 1 / dispScale,
            mimeType: `image/png`,
        })
        shadowRef.current?.show()
        navigate({
            to: "tile_creator",
            state: {
                image: dataURL,
                width: w,
                height: h,
                playerName: playerName,
            },
        })
    }

    useEffect(() => {
        imageGroupRef?.current?.hide()
        shadowRef.current?.hide()
        const dataURL = stageRef?.current?.toDataURL({
            pixelRatio: 1 / dispScale,
        })
        setTextDataURL(dataURL)
        imageGroupRef?.current?.show()
        shadowRef.current?.show()
    })

    async function handleSubmit(e) {
        e.preventDefault()
        const fd = new FormData(e.target)
        const body = transformFormDataForAPI(fd, format)
        const res = await mutate({ orderId, ...body })
        console.log(res)
    }

    return (
        <form
            onSubmit={handleSubmit}
            className="flex items-center justify-between"
        >
            <input
                type="hidden"
                name="player_id"
                value={personImageMeta?.playerId}
            />
            {backgroundId && (
                <input
                    type="hidden"
                    name="background_id"
                    value={backgroundId}
                />
            )}
            {clubLogoId && (
                <input type="hidden" name="club_logo_id" value={clubLogoId} />
            )}
            {sportLogoId && (
                <input type="hidden" name="club_type_logo_id" value={sportLogoId} />
            )}
            <input type="hidden" name="card_template_id" value={templateId} />
            <input type="hidden" name="signature" value={cardSignature} />
            <input type="hidden" name="name" value={playerName} />
            <input type="hidden" name="year" value={cardYear} />
            <input type="hidden" name="secondary_line" value={secondaryLine} />
            {textDataURL && (
                <input
                    name="text_image_data"
                    type="hidden"
                    value={textDataURL}
                />
            )}
            {showEditor && (
                <EditCardDetails
                    id="preview"
                    deselect={deselect}
                    rotate={rotate}
                    nameLetterSpacing={nameLetterSpacing}
                    setNameLetterSpacing={setNameLetterSpacing}
                    nameFontSize={nameFontSize}
                    setNameFontSize={setNameFontSize}
                    nameSkew={nameSkew}
                    setNameSkew={setNameSkew}
                    nameRotation={nameRotation}
                    setNameRotation={setNameRotation}
                    signatureLetterSpacing={signatureLetterSpacing}
                    setSignatureLetterSpacing={setSignatureLetterSpacing}
                    cardSignatureFontSize={cardSignatureFontSize}
                    setCardSignatureFontSize={setCardSignatureFontSize}
                    signatureSkew={signatureSkew}
                    setSignatureSkew={setSignatureSkew}
                    signatureRotation={signatureRotation}
                    setSignatureRotation={setSignatureRotation}
                    yearLetterSpacing={yearLetterSpacing}
                    setYearLetterSpacing={setYearLetterSpacing}
                    yearFontSize={yearFontSize}
                    setYearFontSize={setYearFontSize}
                    yearSkew={yearSkew}
                    setYearSkew={setYearSkew}
                    yearRotation={yearRotation}
                    setYearRotation={setYearRotation}
                    secondaryLineLetterSpacing={secondaryLineLetterSpacing}
                    setSecondaryLineLetterSpacing={
                        setSecondaryLineLetterSpacing
                    }
                    secondaryLineFontSize={secondaryLineFontSize}
                    setSecondaryLineFontSize={setSecondaryLineFontSize}
                    secondaryLineSkew={secondaryLineSkew}
                    setSecondaryLineSkew={setSecondaryLineSkew}
                    secondaryLineRotation={secondaryLineRotation}
                    setSecondaryLineRotation={setSecondaryLineRotation}
                    scale={scale}
                    setScale={setScale}
                    rotation={rotation}
                    hasPersonImage={Boolean(personImage)}
                    isLoading={isLoading}
                    nameColor={nameColor}
                    setNameColor={setNameColor}
                    signatureColor={signatureColor}
                    setSignatureColor={setSignatureColor}
                    yearColor={yearColor}
                    setYearColor={setYearColor}
                    secondaryLineColor={secondaryLineColor}
                    setSecondaryLineColor={setSecondaryLineColor}
                    brandLogoConfig={brandLogoConfig}
                    rotateBrand={rotateBrand}
                    hasBrandLogo={Boolean(brandLogoImage)}
                    teamLogoConfig={teamLogoConfig}
                    hasTeamLogo={Boolean(logoImage)}
                    rotateLogo={rotateLogo}
                />
            )}
            <div className="w-[600px]">
                <div className={cardContainer}>
                    <Stage
                        ref={stageRef}
                        width={w}
                        height={h}
                        onClick={deselect}
                        onTap={deselect}
                    >
                        <Layer ref={shadowRef}>
                            <Rect
                                fill="black"
                                opacity={0.5}
                                shadowColor="black"
                                shadowBlur={10}
                                shadowOpacity={0.5}
                                cornerRadius={16}
                                shadowOffsetX={4}
                                shadowOffsetY={4}
                                width={innerWidth}
                                height={innerHeight}
                                x={bleedX * dispScale}
                                y={bleedY * dispScale}
                            />
                        </Layer>
                        <Layer
                            onDblClick={onSelect}
                            onDblTap={onSelect}
                            clipFunc={(ctx) => {
                                const x = bleedX * dispScale
                                const y = bleedY * dispScale
                                clipSquare(
                                    ctx,
                                    x,
                                    y,
                                    innerWidth,
                                    innerHeight,
                                    16
                                )
                            }}
                        >
                            <Group ref={imageGroupRef}>
                                <BackgroundImage
                                    src={background}
                                    width={w}
                                    height={h}
                                />

                                {personImage && (
                                    <PlayerImage
                                        shapeRef={shapeRef}
                                        src={personImage}
                                        containerWidth={w}
                                        containerHeight={h}
                                        width={pw}
                                        height={ph}
                                        config={imageConfig}
                                        scale={scale}
                                        setScale={setScale}
                                        rotation={rotation}
                                        setRotation={setRotation}
                                        isSelected={isSelected}
                                        points={points}
                                        setPoints={setPoints}
                                    />
                                )}
                                <ForegroundImage
                                    src={frontImage}
                                    width={w}
                                    height={h}
                                />
                                <ImageLoader
                                    src={logoImage}
                                    containerWidth={w}
                                    containerHeight={h}
                                    config={teamLogoConfig}
                                    forwardRef={logoRef}
                                />
                                <ImageLoader
                                    src={brandLogoImage}
                                    containerWidth={w}
                                    containerHeight={h}
                                    config={brandLogoConfig}
                                    forwardRef={brandRef}
                                />
                            </Group>

                            <Group>
                                <TextContainer
                                    name="name"
                                    fontsLoaded
                                    text={playerName}
                                    letterSpacing={nameLetterSpacing}
                                    fontSize={nameFontSize * dispScale}
                                    visible={Number(nameFontSize) > 0}
                                    {...transformTextConfig(nameConfig, {
                                        width: w,
                                        height: h,
                                    })}
                                    skew={nameSkew}
                                    rotation={nameRotation}
                                    fill={nameColor}
                                />
                                <TextContainer
                                    name="signature"
                                    fontsLoaded
                                    text={cardSignature}
                                    letterSpacing={signatureLetterSpacing}
                                    visible={Number(cardSignatureFontSize) > 0}
                                    fontSize={cardSignatureFontSize * dispScale}
                                    {...transformTextConfig(signatureConfig, {
                                        width: w,
                                        height: h,
                                    })}
                                    skew={signatureSkew}
                                    rotation={signatureRotation}
                                    fill={signatureColor}
                                />
                                <TextContainer
                                    name="year"
                                    fontsLoaded
                                    letterSpacing={yearLetterSpacing}
                                    text={cardYear}
                                    visible={yearFontSize > 0}
                                    fontSize={yearFontSize * dispScale}
                                    {...transformTextConfig(yearConfig, {
                                        width: w,
                                        height: h,
                                    })}
                                    skew={yearSkew}
                                    rotation={yearRotation}
                                    fill={yearColor}
                                />
                                <TextContainer
                                    name="secondary_line"
                                    fontsLoaded
                                    letterSpacing={secondaryLineLetterSpacing}
                                    text={secondaryLine}
                                    visible={secondaryLineFontSize > 0}
                                    fontSize={secondaryLineFontSize * dispScale}
                                    {...transformTextConfig(
                                        secondaryLineConfig,
                                        {
                                            width: w,
                                            height: h,
                                        }
                                    )}
                                    skew={secondaryLineSkew}
                                    rotation={secondaryLineRotation}
                                    fill={secondaryLineColor}
                                />
                            </Group>
                        </Layer>
                    </Stage>
                </div>
                <div
                    className="flex items-center gap-4 justify-end mx-auto"
                    style={{ width: `${w}px` }}
                >
                    <button
                        type="button"
                        className="rounded-md bg-white hover:text-primary"
                        onClick={() => setShowEditor(!showEditor)}
                    >
                        <Edit className="h-6 w-6" />
                    </button>
                    <button
                        type="button"
                        className="h-12 flex items-center hover:text-primary"
                        onClick={downloadImage}
                        type="button"
                    >
                        <Download className="h-6 w-6" />
                        <span className="ml-2">Download Image</span>
                    </button>

                    <button
                        type="button"
                        className="flex items-center hover:text-primary h-12"
                        onClick={goToTileCreator}
                    >
                        Create Tile
                    </button>
                </div>
            </div>
        </form>
    )
}

export default Display
