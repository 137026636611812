import React from "react"
import { useQueries } from "react-query"
import {
    fetchBackgrounds,
    fetchLogos,
    fetchSportsLogos,
    fetchTemplates,
} from "../apis"
import BackgroundSelection from "./toolbar/BackgroundSelection"
import TextLayerSelection from "./toolbar/TextLayerSelection"
import BackImageSelection from "./toolbar/BackImageSelection"
import FrontImageSelection from "./toolbar/FrontImageSelection"

function transformImagePathToObject(image) {
    if (!image) {
        return null
    }
    const splitPath = image.split("/")
    const filename = splitPath[splitPath.length - 1]
    const id = splitPath[splitPath.length - 3] + splitPath[splitPath.length - 2] + filename
    return {
        id,
        name: decodeURI(filename),
        image,
    }
}

function Toolbar({ children }) {
    const [backgrounds, logos, sports, templates] = useQueries([
        {
            queryKey: ["backgrounds"],
            queryFn: fetchBackgrounds,
            staleTime: Infinity,
        },
        {
            queryKey: ["club_logos"],
            queryFn: fetchLogos,
            staleTime: Infinity,
        },
        {
            queryKey: ["sports_logos"],
            queryFn: fetchSportsLogos,
            staleTime: Infinity,
        },
        {
            queryKey: ["templates"],
            queryFn: fetchTemplates,
            staleTime: Infinity,
        },
    ])

    const frontImages = [
        ...new Set(templates?.data?.map((t) => t.front_image)),
    ].map(transformImagePathToObject) ?? []
    const backImages = [...new Set(templates?.data?.map((t) => t.back_image))].map(transformImagePathToObject).filter(Boolean) ?? []
    const backgroundImages = backgrounds?.data ?? []

    return (
        <div className="z-[100] fixed w-12 top-[40px] bottom-0 left-0 flex flex-col bg-white border-t border-r flex flex-col items-center">
            <BackgroundSelection backgrounds={backgroundImages} />
            <FrontImageSelection frontImages={frontImages} />
            <TextLayerSelection />
            <hr className="my-2 border-t-2 w-full"/>
            <BackImageSelection backImages={backImages} />
            {children}
        </div>
    )
}

export default Toolbar
