import React from "react"

function UploadImage({ id, label, setImages }) {
    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files)
        const fileReaders = selectedFiles.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onloadend = () => {
                    resolve({ name: file.name, image: reader.result })
                }
                reader.onerror = reject
                reader.readAsDataURL(file)
            })
        })

        Promise.allSettled(fileReaders)
            .then((results) => {
                const successfulFiles = results
                    .filter((result) => result.status === "fulfilled")
                    .map((result) => result.value)

                setImages((prevFiles) =>
                    [...prevFiles, ...successfulFiles].map((f, id) => ({
                        ...f,
                        id,
                    }))
                )
            })
            .catch((error) => {
                console.error("Error reading files:", error)
            })

        event.target.value = ""
    }

    return (
        <div className="flex items-center justify-center ">
            <label htmlFor={id} className="cursor-pointer px-4 py-2 border rounded-md border-primary hover:border-indigo-600 hover:text-indigo-600 text-primary">
                {label}
            </label>
            <input
                className="sr-only"
                type="file"
                accept="image/*"
                multiple
                onInput={handleFileChange}
                id={id}
                value={undefined}
            />
        </div>
    )
}

export default UploadImage
