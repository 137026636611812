import React, { useRef } from "react"
import useCreateTemplate from "../components/hooks/useCreateTemplate"
import useTemplateDraft from "../components/hooks/useTemplateDraft"
import { useAtomValue } from "jotai"
import { sessionAtom } from "./atoms"
import { enqueueSnackbar } from "notistack"

function CreateTemplateFooter() {
    const ref = useRef()
    const mutation = useCreateTemplate()
    const drafts = useAtomValue(sessionAtom)
    const { saveDraft, loadDraft } = useTemplateDraft()

    function onSuccess(data) {
        if ("errors" in data) {
            enqueueSnackbar(data.errors.join(""), { variant: "error" })
        } else {
            enqueueSnackbar(
                `Template created with the name: ${data.template_name}`,
                { variant: "success" }
            )
        }
    }
    
    function onError(e) {
        enqueueSnackbar("Something went wrong", { variant: "error" })
    }

    function onCreateTemplate(e) {
        e.preventDefault()
        mutation.mutate(e.target.templateName.value, {
            onSuccess,
            onError,
        })
    }

    function onSaveDraft(e) {
        e.preventDefault()
        const draftName = e.target.draftName
        saveDraft(draftName.value)
        e.target.reset()
    }

    function onLoadDraft(e) {
        e.preventDefault()
        const draftName = e.target.draftSelection
        loadDraft(draftName.value)
        if (ref?.current) {
            ref.current.value = draftName.value
        }
        e.target.reset()
    }

    return (
        <footer className="fixed flex items-center justify-center gap-2 bottom-0 right-0 left-12 p-4 bg-white">
            <form
                className="flex items-center px-4 py-2 gap-2"
                onSubmit={onLoadDraft}
            >
                <select
                    name="draftSelection"
                    className="border py-2 px-4 rounded-md"
                >
                    <option value="" disabled>
                        Select One
                    </option>
                    {Object.keys(drafts).map((opt, i) => (
                        <option value={opt} key={i}>
                            {opt}
                        </option>
                    ))}
                </select>
                <button
                    type="submit"
                    className="px-4 py-2 border border-primary text-primary hover:bg-primary hover:text-white rounded-md"
                >
                    Load Draft
                </button>
            </form>
            <form
                className="flex items-center px-4 py-2 gap-2"
                onSubmit={onSaveDraft}
            >
                <label
                    htmlFor="draftName"
                    className="text-xs uppercase font-bold"
                >
                    Draft Name
                </label>
                <input
                    id="draftName"
                    type="text"
                    name="draftName"
                    required
                    className="border p-2 rounded-md"
                    ref={ref}
                />
                <button
                    type="submit"
                    className="px-4 py-2 border border-primary text-primary hover:bg-primary hover:text-white rounded-md"
                >
                    Save Draft
                </button>
            </form>
            <form
                className="flex items-center px-4 py-2 gap-2"
                onSubmit={onCreateTemplate}
            >
                <label
                    htmlFor="templateName"
                    className="text-xs uppercase font-bold"
                >
                    Template Name
                </label>
                <input
                    id="templateName"
                    type="text"
                    name="templateName"
                    required
                    className="border p-2 rounded-md"
                />
                <button className="px-4 py-2 bg-primary hover:bg-indigo-600 text-white rounded-md">
                    Create Template
                </button>
            </form>
        </footer>
    )
}

export default CreateTemplateFooter
