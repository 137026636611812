import { useAtomValue } from "jotai"
import {
    selectedBackImageAtom,
    selectedBgAtom,
    selectedFrontImageAtom,
    TextLayerConfig,
    textLayersAtom,
} from "../../template_creator/atoms"
import { useMutation } from "react-query"
import { useEffect, useState } from "react"
// [
//      :template_name,
//      :front_image_data,
//      :back_image_data,
//      :background_id,
//      :default_card_logo_id,
//      :brand_logo_id,
//      :tag_image_id,
//      :is_available_on_demo,
//      :back_template_id,
//      :name_position=>[:x, :y, :rotation, :font_size, :min_font_size, :max_font_size, :font_face, :h_alignment, :font_color, :skew_x, :skew_y, :letter_spacing, :v_alignment, :width, :height],
//      :year_position=>[:x, :y, :rotation, :font_size, :min_font_size, :max_font_size, :font_face, :h_alignment, :font_color, :skew_x, :skew_y, :letter_spacing, :v_alignment, :width, :height],
//      :logo_position=>[:x, :y, :rotation, :height],
//      :brand_logo_position=>[:x, :y, :rotation, :height],
//      :tag_image_position=>[:x, :y, :rotation, :height],
//      :signature_position=>[:x, :y, :rotation, :font_size, :min_font_size, :max_font_size, :font_face, :h_alignment, :font_color, :skew_x, :skew_y, :letter_spacing, :v_alignment, :width, :height],
//      :secondary_line_position=>[:x, :y, :rotation, :font_size, :min_font_size, :max_font_size, :font_face, :h_alignment, :font_color, :skew_x, :skew_y, :letter_spacing, :v_alignment, :width, :height]
// ]

// # Text Element Constants
// H_ALIGNMENT_OPTS = %w[Left Center Right Custom].freeze
// V_ALIGNMENT_OPTS = %w[Top Center Bottom Custom].freeze

function transformAlignmentForAPI(alignment?: string): string {
    if (!alignment) {
        return "Center"
    }

    const alignmentsForAPI = {
        left: "Left",
        center: "Center",
        right: "Right",
        top: "Top",
        middle: "Center",
        bottom: "Bottom",
    }
    const k = alignment.toLocaleLowerCase()
    return alignmentsForAPI[k]
}

function toDataUrl(url, callback) {
    if (!url) {
        return false
    }

    var xhr = new XMLHttpRequest()
    xhr.onload = function () {
        var reader = new FileReader()
        reader.onloadend = function () {
            callback(reader.result)
        }
        reader.readAsDataURL(xhr.response)
    }
    xhr.open("GET", url)
    xhr.responseType = "blob"
    xhr.send()
}

function createTextConfig(text: TextLayerConfig) {
    const isHidden = text.hidden
    return {
        x: text.x,
        y: text.y,
        min_font_size: 14,
        max_font_size: 72,
        font_size: isHidden ? 0 : text.fontSize,
        font_color: text.fill,
        letter_spacing: text.letterSpacing,
        skew_x: text.skew.x,
        skew_y: text.skew.y,
        rotation: text.rotation,
        v_alignment: transformAlignmentForAPI(text.verticalAlign),
        h_alignment: transformAlignmentForAPI(text.align),
        ...(text?.width ? { width: text.width } : {}),
        ...(text?.height ? { height: text.height } : {}),
    }
}

function useCreateTemplate() {
    const [backImage64, setBackImage64] = useState("")
    const [frontImage64, setFrontImage64] = useState("")
    const [, setBackground64] = useState("")
    const background = useAtomValue(selectedBgAtom)
    const backImage = useAtomValue(selectedBackImageAtom)
    const frontImage = useAtomValue(selectedFrontImageAtom)
    const textLayers = useAtomValue(textLayersAtom)

    const [namePos, secondaryLinePos, signaturePos, yearPos] = textLayers

    useEffect(() => {
        toDataUrl(backImage?.image, (result) => setBackImage64(result))
        toDataUrl(frontImage?.image, (result) => setFrontImage64(result))
        toDataUrl(background?.image, (result) => setBackground64(result))
    }, [background, backImage, frontImage])

    const store = {
        is_available_in_demo: true,
        background_id: background?.id,
        back_template_id: "",
        name: namePos.text,
        name_position: createTextConfig(namePos),
        secondary_line: secondaryLinePos.text,
        secondary_line_position: createTextConfig(secondaryLinePos),
        signature: signaturePos.text,
        signature_position: createTextConfig(signaturePos),
        year: yearPos.text,
        year_position: createTextConfig(yearPos),
        front_image_data: frontImage64,
        back_image_data: backImage64,
    }

    const mutation = useMutation({
        mutationFn: async (templateName) => {
            const res = await fetch(`/api/card_templates`, {
                method: "POST",
                headers: { "Content-type": "application/json; charset=UTF-8" },
                body: JSON.stringify({ ...store, template_name: templateName }),
            })
            const data = await res.json()
            return data
        },
    })

    return mutation
}

export default useCreateTemplate
