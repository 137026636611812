import React, { useEffect } from "react"
import useFonts from "./hooks/useFonts"
import { useMutation, useQuery } from "react-query"
import Container from "./Container"
import { Loader2Icon } from "lucide-react"
import { DataT, transformFormDataForAPI } from "../utils"

function App() {
    const splitPathname = window.location.pathname.split("/")
    const orderIdx = splitPathname.indexOf("orders")
    const id = splitPathname.slice(orderIdx + 1, orderIdx + 2).pop()
    const { isLoaded } = useFonts()
    const { data, isFetching, isFetched } = useQuery({
        queryKey: ["cardOrders", id],
        queryFn: async ({ queryKey: [, id] }) => {
            if (id) {
                const res = await fetch(`/api/orders/${id}/order_cards`)
                const orders = await res.json()
                return orders as DataT[]
            } else {
                return []
            }
        },
    })

    const mutation = useMutation({
        mutationFn: async (cards) => {
            const res = await fetch(`/api/orders/${id}/update_cards`, {
                method: "PUT",
                headers: { "Content-type": "application/json; charset=UTF-8" },
                body: JSON.stringify({ cards }),
            })
            const data = await res.json()
            return data
        },
    })

    async function saveProgress() {
        const els = [...document.querySelectorAll("form")]
        const body = els
            .map((el) => {
                const formData = new FormData(el)
                const format = el.getAttribute('data-template-format')
                return transformFormDataForAPI(formData, format)
            })
        mutation.mutate(body)
    }

    function fixText() {
        const els = [...document.querySelectorAll(".js-fixtext")]
        els.forEach(el => el.click())
    }

    useEffect(() => {
        let timeoutId
        if (mutation.isSuccess || mutation.isError) {
            timeoutId = setTimeout(() => {
                mutation.reset()
            }, 3000)
        }

        return () => timeoutId && clearTimeout(timeoutId)
    }, [mutation?.isSuccess, mutation?.isError])

    if (isFetching) {
        return (
            <div className="p-8">
                <Loader2Icon className="animate-spin" size={72} />
            </div>
        )
    }

    return (
        <>
            <div className="p-5 flex flex-wrap gap-8">
                {data?.map((card) => (
                    <Container
                        key={card.id}
                        data={card}
                        fontsLoaded={true}
                    />
                ))}
            </div>
            <div className="sticky bottom-0 right-0 left-0 w-full bg-white p-8 border flex items-center gap-2">
                <button
                    type="button"
                    className="flex items-center !bg-indigo-500 !w-auto px-4 py-2 !text-white !rounded-lg"
                    disabled={mutation.isLoading}
                    onClick={saveProgress}
                >
                    {mutation.isLoading ? (
                        <>
                            <Loader2Icon size={14} className="animate-spin" />
                            <span className="ml-2">Saving...</span>
                        </>
                    ) : (
                        <span>Save Changes</span>
                    )}
                </button>
                <button
                    type="button"
                    className="flex items-center !bg-indigo-500 !w-auto px-4 py-2 !text-white !rounded-lg"
                    disabled={mutation.isLoading}
                    onClick={fixText}
                >
                    Fix Text Centering
                </button>
                {mutation.isSuccess && (
                    <span className="text-green-500">Changes saved!</span>
                )}
                {mutation.isError && (
                    <span className="text-red-500">
                        Something went wrong. Please try again.
                    </span>
                )}
            </div>
        </>
    )
}

export default App
