import React from "react"
import { Loader2Icon } from "lucide-react"
import TextSkewInput from "../components/TextSkewInput"
import TextRotationInput from "../components/TextRotationInput"
import {
    MAX_DEG,
    MAX_SCALE,
    MIN_DEG,
    MIN_SCALE,
    SCALE_STEP,
} from "../utils/CONSTANTS"
import ColorPicker from "../components/ColorPicker"
import Label from "../components/Label"

function EditCardDetails({
    deselect,
    id,
    nameLetterSpacing,
    setNameLetterSpacing,
    nameFontSize,
    setNameFontSize,
    nameColor,
    setNameColor,
    nameSkew,
    setNameSkew,
    nameRotation,
    setNameRotation,
    signatureLetterSpacing,
    setSignatureLetterSpacing,
    cardSignatureFontSize,
    setCardSignatureFontSize,
    signatureColor,
    setSignatureColor,
    signatureSkew,
    setSignatureSkew,
    signatureRotation,
    setSignatureRotation,
    yearLetterSpacing,
    setYearLetterSpacing,
    yearFontSize,
    setYearFontSize,
    yearColor,
    setYearColor,
    yearSkew,
    setYearSkew,
    yearRotation,
    setYearRotation,
    scale,
    setScale,
    rotation,
    rotate,
    hasPersonImage,
    secondaryLineLetterSpacing,
    setSecondaryLineLetterSpacing,
    secondaryLineFontSize,
    setSecondaryLineFontSize,
    secondaryLineColor,
    setSecondaryLineColor,
    secondaryLineSkew,
    setSecondaryLineSkew,
    secondaryLineRotation,
    setSecondaryLineRotation,
    isLoading,

    brandLogoConfig,
    rotateBrand,
    hasBrandLogo,

    teamLogoConfig,
    rotateLogo,
    hasTeamLogo,
}) {
    return (
        <div className="p-8 w-1/2">
            <div
                className="flex flex-col rounded-md shadow-md bg-slate-50 max-h-[600px] overflow-auto gap-4 divide-y-2 border relative"
                onClick={deselect}
                tabIndex={-1}
            >
                <div className="flex flex-wrap gap-1 p-4">
                    <p className="w-full">Year</p>
                    <div className="flex w-full items-start gap-2">
                        <div className="flex flex-col w-1/3">
                            <Label htmlFor={`${id}-yearLetterSpacing`}>
                                Letter Spacing
                            </Label>
                            <input
                                id={`${id}-yearLetterSpacing`}
                                name="year.letter_spacing"
                                type="number"
                                className="bg-white p-2 border rounded-md w-full"
                                step={0.1}
                                value={yearLetterSpacing}
                                onChange={(e) =>
                                    setYearLetterSpacing(Number(e.target.value))
                                }
                            />
                        </div>
                        <div className="flex flex-col w-1/3">
                            <Label htmlFor={`${id}-yearFontSize`}>
                                Font Size
                            </Label>
                            <input
                                id={`${id}-yearFontSize`}
                                type="number"
                                name="year.font_size"
                                className="bg-white p-2 border rounded-md w-full"
                                value={yearFontSize}
                                onChange={(e) =>
                                    setYearFontSize(e.target.value)
                                }
                            />
                        </div>
                        <div className="flex">
                            <ColorPicker
                                color={yearColor}
                                name="year"
                                onChange={(color) => setYearColor(color.hexa)}
                            />
                        </div>
                    </div>

                    {/* <div className="flex items-end gap-1">
                        <TextSkewInput
                            skew={yearSkew}
                            setSkew={setYearSkew}
                            name="year"
                        />
                        <TextRotationInput
                            rotation={yearRotation}
                            name="year"
                            setRotation={setYearRotation}
                        />
                    </div> */}
                </div>

                <div className="flex flex-wrap gap-1 p-4">
                    <p className="w-full">Player Name</p>
                    <div className="flex w-full items-start gap-2">
                        <div className="flex flex-col w-1/3">
                            <Label htmlFor={`${id}-nameLetterSpacing`}>
                                Letter Spacing
                            </Label>
                            <input
                                id={`${id}-nameLetterSpacing`}
                                name="name.letter_spacing"
                                type="number"
                                step={0.1}
                                className="bg-white p-2 border rounded-md w-full"
                                value={nameLetterSpacing}
                                onChange={(e) =>
                                    setNameLetterSpacing(Number(e.target.value))
                                }
                            />
                        </div>
                        <div className="flex flex-col w-1/3">
                            <Label htmlFor={`${id}-nameFontSize`}>
                                Font Size
                            </Label>
                            <input
                                id={`${id}-nameFontSize`}
                                name="name.font_size"
                                type="number"
                                className="bg-white p-2 border rounded-md w-full"
                                value={nameFontSize}
                                onChange={(e) =>
                                    setNameFontSize(e.target.value)
                                }
                            />
                        </div>
                        <div className="flex">
                            <ColorPicker
                                color={nameColor}
                                name="name"
                                onChange={(color) => setNameColor(color.hexa)}
                            />
                        </div>
                    </div>
                    {/* <div className="flex items-end gap-1">
                        <TextSkewInput
                            skew={nameSkew}
                            setSkew={setNameSkew}
                            name="name"
                        />
                        <TextRotationInput
                            rotation={nameRotation}
                            name="name"
                            setRotation={setNameRotation}
                        />
                    </div> */}
                </div>

                <div className="flex flex-wrap gap-1 p-4">
                    <p className="w-full">Secondary Line</p>
                    <div className="flex w-full items-start gap-2">
                        <div className="flex flex-col w-1/3">
                            <Label htmlFor={`${id}-secondaryLineLetterSpacing`}>
                                Letter Spacing
                            </Label>
                            <input
                                id={`${id}-secondaryLineLetterSpacing`}
                                name="secondary_line.letter_spacing"
                                type="number"
                                className="bg-white p-2 border rounded-md w-full"
                                step={0.1}
                                value={secondaryLineLetterSpacing}
                                onChange={(e) =>
                                    setSecondaryLineLetterSpacing(
                                        Number(e.target.value)
                                    )
                                }
                            />
                        </div>
                        <div className="flex flex-col w-1/3">
                            <Label htmlFor={`${id}-secondaryLineFontSize`}>
                                Font Size
                            </Label>
                            <input
                                id={`${id}-secondaryLineFontSize`}
                                type="number"
                                name="secondary_line.font_size"
                                className="bg-white p-2 border rounded-md w-full"
                                value={secondaryLineFontSize}
                                onChange={(e) =>
                                    setSecondaryLineFontSize(e.target.value)
                                }
                            />
                        </div>
                        <div className="flex">
                            <ColorPicker
                                color={secondaryLineColor}
                                name="secondary_line"
                                onChange={(color) =>
                                    setSecondaryLineColor(color.hexa)
                                }
                            />
                        </div>
                    </div>
                    {/* <div className="flex items-end gap-1">
                        <TextSkewInput
                            skew={secondaryLineSkew}
                            setSkew={setSecondaryLineSkew}
                            name="secondary_line"
                        />
                        <TextRotationInput
                            rotation={secondaryLineRotation}
                            name="secondary_line"
                            setRotation={setSecondaryLineRotation}
                        />
                    </div> */}
                </div>

                <div className="flex flex-wrap gap-1 p-4">
                    <p className="w-full">Signature</p>
                    <div className="flex w-full items-start gap-2">
                        <div className="flex flex-col w-1/3">
                            <Label htmlFor={`${id}-signatureLetterSpacing`}>
                                Letter Spacing
                            </Label>
                            <input
                                id={`${id}-signatureLetterSpacing`}
                                name="signature.letter_spacing"
                                type="number"
                                step={0.1}
                                className="bg-white p-2 border rounded-md w-full"
                                value={signatureLetterSpacing}
                                onChange={(e) =>
                                    setSignatureLetterSpacing(
                                        Number(e.target.value)
                                    )
                                }
                            />
                        </div>
                        <div className="flex flex-col w-1/3">
                            <Label htmlFor={`${id}-signFontSize`}>
                                Font Size
                            </Label>
                            <input
                                id={`${id}-signFontSize`}
                                type="number"
                                name="signature.font_size"
                                className="bg-white p-2 border rounded-md w-full"
                                value={cardSignatureFontSize}
                                onChange={(e) =>
                                    setCardSignatureFontSize(e.target.value)
                                }
                            />
                        </div>
                        <div className="flex">
                            <ColorPicker
                                color={signatureColor}
                                name="signature"
                                onChange={(color) =>
                                    setSignatureColor(color.hexa)
                                }
                            />
                        </div>
                    </div>
                    {/* <div className="flex items-end gap-1">
                        <TextSkewInput
                            skew={signatureSkew}
                            setSkew={setSignatureSkew}
                            name="signature"
                        />
                        <TextRotationInput
                            rotation={signatureRotation}
                            name="signature"
                            setRotation={setSignatureRotation}
                        />
                    </div> */}
                </div>

                {hasPersonImage && (
                    <>
                        <div className="flex flex-col p-2">
                            <Label>Zoom {scale}</Label>
                            <input
                                type="range"
                                className="w-full"
                                onChange={(e) =>
                                    setScale(Number(e.target.value))
                                }
                                min={MIN_SCALE}
                                step={SCALE_STEP}
                                max={MAX_SCALE}
                                value={scale}
                            />
                        </div>
                        <div className="flex flex-col p-2">
                            <Label>Rotation {rotation + "deg"}</Label>
                            <input
                                type="range"
                                onChange={rotate}
                                min={MIN_DEG}
                                step={0.01}
                                max={MAX_DEG}
                                value={rotation}
                            />
                        </div>
                    </>
                )}
                {hasBrandLogo && (
                    <div className="flex flex-col p-2">
                        <Label>
                            <span>
                                Brand Logo Rotation{" "}
                                {brandLogoConfig.rotation + "deg"}
                            </span>
                            <span>x: {Number(brandLogoConfig.x).toFixed(2)}</span>
                            <span>y: {Number(brandLogoConfig.y).toFixed(2)}</span>
                        </Label>
                        <input
                            type="range"
                            onChange={rotateBrand}
                            min={MIN_DEG}
                            step={0.01}
                            max={MAX_DEG}
                            value={brandLogoConfig.rotation}
                        />
                        <input
                            type="hidden"
                            name="brand.x"
                            value={brandLogoConfig.x}
                        />
                        <input
                            type="hidden"
                            name="brand.y"
                            value={brandLogoConfig.y}
                        />
                        <input
                            type="hidden"
                            name="brand.rotation"
                            value={brandLogoConfig.rotation}
                        />
                    </div>
                )}
                {hasTeamLogo && (
                    <div className="flex flex-col p-2">
                        <Label>
                            <span>
                                Club Logo Rotation{" "}
                                {teamLogoConfig.rotation + "deg"}
                            </span>
                            <span>x: {Number(teamLogoConfig.x).toFixed(2)}</span>
                            <span>y: {Number(teamLogoConfig.y).toFixed(2)}</span>
                        </Label>
                        <input
                            type="range"
                            onChange={rotateLogo}
                            min={MIN_DEG}
                            step={0.01}
                            max={MAX_DEG}
                            value={teamLogoConfig.rotation}
                        />
                        <input
                            type="hidden"
                            name="logo.x"
                            value={teamLogoConfig.x}
                        />
                        <input
                            type="hidden"
                            name="logo.y"
                            value={teamLogoConfig.y}
                        />
                        <input
                            type="hidden"
                            name="logo.rotation"
                            value={teamLogoConfig.rotation}
                        />
                    </div>
                )}
                {/* <div className="flex items-center">
                    <button
                        type="submit"
                        disabled={isLoading}
                        className="!flex !items-center justify-center !bg-indigo-500 !rounded !w-36"
                    >
                        {isLoading ? (
                            <>
                                <Loader2Icon
                                    className="animate-spin mr-2"
                                    size={12}
                                />
                                <span>Saving...</span>
                            </>
                        ) : (
                            <span>Save</span>
                        )}
                    </button>
                    <button onClick={() => downloadURI(dataUrl, "text-layer")}>
                        Download Text
                    </button>
                    <button
                        style={{ display: "none" }}
                        className="js-fixtext"
                        onClick={fixText}
                    >
                        Fix Text
                    </button>
                </div> */}
                <div className="p-2 sticky bottom-0 w-full bg-slate-50">
                    {isLoading ? (
                        <>
                            <Loader2Icon
                                className="animate-spin mr-2"
                                size={12}
                            />
                            <span>Saving...</span>
                        </>
                    ) : (
                        <button
                            type="submit"
                            className="bg-primary hover:bg-indigo-600 rounded-md px-4 py-2 text-white"
                        >
                            Save as Order
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default EditCardDetails
