
export const fetchBackgrounds = async () => {
    const res = await fetch("/api/backgrounds")
    return await res.json()
}

export const fetchBackTemplates = async () => {
    const res = await fetch("/api/back_templates")
    return await res.json()
}

export const fetchTemplates = async () => {
    const res = await fetch("/api/card_templates")
    return await res.json()
}
export const fetchLogos = async () => {
    const res = await fetch("/api/logos/club")
    return await res.json()
}

export const fetchSportsLogos = async () => {
    const res = await fetch("/api/logos/sports")
    return await res.json()
}