import React from "react"
import { Image } from "react-konva"
import useImage from "use-image"

function BackgroundImage({
    src,
    width,
    height,
}: {
    src: string
    width: number
    height: number
}) {
    const [image] = useImage(src, 'anonymous')
    if (!image) {
        return null
    }
    return <Image image={image} width={width} height={height} y={0} />
}

export default BackgroundImage
