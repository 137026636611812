import React, { Suspense, StrictMode } from "react"
import { createRoot } from "react-dom/client"
import { QueryClient, QueryClientProvider } from "react-query"
import { RouterProvider } from "@tanstack/react-router"
import router from "./routes"
import "../stylesheet/index.css"
import Konva from "konva"

Konva.hitOnDragEnabled = true
Konva.captureTouchEventsEnabled = true

const container = document.getElementById("preview")
if (container) {
    const root = createRoot(container!)
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    })
    root.render(
        <StrictMode>
            <QueryClientProvider client={queryClient}>
                <Suspense fallback={<p>Loading</p>}>
                    <RouterProvider router={router} />
                </Suspense>
            </QueryClientProvider>
        </StrictMode>
    )
}
