import React from "react"
import { Image } from "react-konva"
import useImage from "use-image"

function ImageLoader({
    src,
    containerWidth,
    containerHeight,
    config,
    forwardRef,
}: {
    src: string
    containerWidth: number
    containerHeight: number
    config: any
    forwardRef: any
}) {
    const [image] = useImage(src, 'anonymous')
    if (!image) {
        return null
    }

    const aspectRatio = image.height / image.width
    const x = (config.x / 100) * containerWidth
    const y = (config.y / 100) * containerHeight
    const height = (config.height / 100) * containerHeight
    const width = height / aspectRatio
    return (
        <Image
            ref={forwardRef}
            image={image}
            width={width}
            height={height}
            listening={false}
            x={x}
            y={y}
            rotation={config.rotation}
        />
    )
}

export default ImageLoader
