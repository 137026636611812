import { RotateCcwIcon } from "lucide-react"
import React from "react"

function TextSkewInput({ name, skew = { x: 0, y: 0 }, setSkew }) {
    return (
        <div className="flex gap-1 w-[200]">
            <div className="flex w-1/2 flex-col">
                <div className="flex w-full items-center gap-1">
                    <label className="mr-auto w-24 text-xs uppercase">
                        Skew X <span className="font-bold">{skew.x}</span>
                    </label>
                    <button
                        className="bg-transparent text-slate-500 hover:text-white hover:bg-slate-500 rounded-md p-2"
                        type="reset"
                        title="Reset X"
                        onClick={() => setSkew({ ...skew, x: 0 })}
                    >
                        <RotateCcwIcon className="w-4 h-4"/>
                    </button>
                </div>
                <input
                    name={`${name}.skew_x`}
                    type="range"
                    onChange={(e) =>
                        setSkew({
                            ...skew,
                            x: Number(e.target.value),
                        })
                    }
                    min={-1}
                    step={0.01}
                    max={1}
                    value={skew.x}
                />
            </div>
            <div className="flex w-1/2 flex-col">
                <div className="flex  w-full items-center gap-1">
                    <label className="mr-auto w-24 text-xs uppercase">
                        Skew Y <span className="font-bold">{skew.y}</span>
                    </label>
                    <button
                        className="bg-transparent text-slate-500 hover:text-white hover:bg-slate-500 rounded-md p-2"
                        type="reset"
                        title="Reset Y"
                        onClick={() => setSkew({ ...skew, y: 0 })}
                    >
                        <RotateCcwIcon className="w-4 h-4"/>
                    </button>
                </div>
                <input
                    name={`${name}.skew_y`}
                    type="range"
                    onChange={(e) =>
                        setSkew({
                            ...skew,
                            y: Number(e.target.value),
                        })
                    }
                    min={-1}
                    step={0.01}
                    max={1}
                    value={skew.y}
                />
            </div>
        </div>
    )
}

export default TextSkewInput
