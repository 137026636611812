import React, {
    SetStateAction,
    Dispatch,
    useState,
    useEffect,
    useRef,
} from "react"
import { Image, Search } from "lucide-react"

function Logo({
    name,
    image,
    isSelected,
    selectLogo,
    isDefault,
    cover,
    darkMode,
    disabled,
}: {
    isDefault?: boolean
}) {
    const cx = [
        "flex flex-col items-center justify-center",
        "rounded-md p-2 border-2",

        isSelected
            ? "border-primary bg-primary text-white"
            : darkMode
            ? "border-slate-500 bg-slate-500 disabled:opacity-50 text-white"
            : "border-white text-primary disabled:opacity-50",
    ]
        .filter(Boolean)
        .join(" ")

    const containerCX = [
        cover ? "bg-cover" : "bg-contain",
        "bg-center bg-no-repeat h-12 w-full",
    ].join(" ")
    return (
        <button
            type="button"
            onClick={selectLogo}
            className={cx}
            disabled={disabled}
        >
            {cover ? <span>{name}</span> : null}
            {isDefault ? (
                <div className="flex items-center justify-center h-12 w-full">
                    <Image className="h-6 w-6" />
                </div>
            ) : (
                <div
                    className={containerCX}
                    style={{ backgroundImage: `url(${image})` }}
                />
            )}
        </button>
    )
}

function LogoSelection({
    data,
    setLogoId,
    selected,
    title,
    cover,
    darkMode,
    disabled,
    id,
}: {
    data: [{ name: string; image: string }]
    setLogoId: Dispatch<SetStateAction<string>>
    selected: string | null
    title: string
    cover: boolean
    darkMode: boolean
    disabled: boolean
    id: string
}) {
    const ref = useRef()
    const [displayTemplates, setDisplayTemplates] = useState(false)
    const [search, setSearch] = useState("")

    useEffect(() => {
        function onClickOutside(e) {
            if (!(ref.current as HTMLElement).contains(e.target)) {
                setDisplayTemplates(false)
            }
        }

        document.addEventListener("click", onClickOutside)

        return () => document.removeEventListener("click", onClickOutside)
    }, [])

    const cx = [
        "border flex items-center px-4 py-2 rounded-md bg-white",
        displayTemplates ? "border-b-0 rounded-b-none z-20" : "",
    ].join(" ")
    return (
        <div className="flex flex-col gap-2 relative bg-white" ref={ref}>
            <button
                className={cx}
                type="button"
                onClick={() => setDisplayTemplates(!displayTemplates)}
            >
                {title}
            </button>

            {displayTemplates && (
                <div className="z-10 max-h-[600px] overflow-auto grid grid-cols-3 gap-1 min-w-[400px] w-[400px] absolute top-10 bg-white border shadow-md rounded-b-md rounded-r-md p-4">
                    <div className="col-span-full flex items-stretch">
                        <label
                            htmlFor={id}
                            className="flex items-center py-2 px-4 border rounded-l-md"
                        >
                            <Search className="w-4 h-4" />
                        </label>
                        <input
                            className="border-r border-y px-3 py-2 rounded-r-md w-full"
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            id={id}
                            placeholder="Search"
                            disabled={disabled}
                        />
                    </div>
                    {data
                        .filter((logo) =>
                            Boolean(search)
                                ? logo?.name
                                      ?.toLowerCase()
                                      ?.includes(search?.toLowerCase())
                                : true
                        )
                        .map((l, i: number) => (
                            <Logo
                                {...l}
                                key={i}
                                isSelected={selected === l.id}
                                selectLogo={() => setLogoId(l.id)}
                                cover={cover}
                                darkMode={darkMode}
                                disabled={disabled}
                            />
                        ))}
                    <Logo
                        isDefault
                        name="Default"
                        isSelected={selected === null}
                        selectLogo={() => setLogoId(null)}
                        darkMode={darkMode}
                        disabled={disabled}
                    />
                </div>
            )}
        </div>
    )
}

export default LogoSelection
