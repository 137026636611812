import React, { useEffect, useRef, useState } from "react"
import { Stage, Layer, Group } from "react-konva"
import BackgroundImage from "./BackgroundImage"
import {
    MAX_DEG,
    MAX_SCALE,
    MIN_DEG,
    MIN_SCALE,
    SCALE_STEP,
} from "../utils/CONSTANTS"
import getEditorDimensions from "../utils/getEditorDimensions"
import PlayerImage from "./PlayerImage"
import ForegroundImage from "./ForegroundImage"
import ImageLoader from "./ImageLoader"
import { useMutation } from "react-query"
import { Loader2Icon } from "lucide-react"
import {
    DataT,
    rotateViaCenter,
    transformFormDataForAPI,
    transformTextConfig,
} from "../utils"
import { Node } from "konva/lib/Node"
import TextContainer from "./TextContainer"
import TextSkewInput from "./TextSkewInput"
import TextRotationInput from "./TextRotationInput"
import ColorPicker from "./ColorPicker"

function transformPercentToPixels(percent, base) {
    return (percent / 100) * base
}

function downloadURI(uri, name) {
    var link = document.createElement("a")
    link.download = name
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

function Container({
    data,
    fontsLoaded,
}: {
    data: DataT
    fontsLoaded: boolean
}) {
    const stageRef = useRef()
    const shapeRef = useRef()
    const logoRef = useRef()
    const brandRef = useRef()
    const imageGroupRef = useRef()
    const { mutate, isLoading } = useMutation({
        mutationKey: ["post"],
        mutationFn: async ({ id, ...props }) => {
            const res = await fetch(`/api/order_cards/${id}`, {
                method: "PUT",
                body: JSON.stringify({ id, ...props }),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            })
            const data = await res.json()
            return data
        },
    })
    const { configuration, signature, year, name, secondary_line, format = "card" } = data
    const { w, h, innerWidth, innerHeight, dispScale, bleedX } = getEditorDimensions(format)
    const pw = data?.person_image_meta?.width * dispScale
    const ph = data?.person_image_meta?.height * dispScale

    const imageConfig = configuration?.image
    const brandConfig = configuration?.brand
    const logoConfig = configuration?.logo
    const nameConfig = configuration?.name
    const signatureConfig = configuration?.signature
    const yearConfig = configuration?.year
    const secondaryLineConfig = configuration?.secondary_line

    const hasBrandLogo = Boolean(data?.brand_logo_image)
    const hasTeamLogo = Boolean(data?.logo_image)
    const [brandLogoConfig, setBrandLogoConfig] = useState(brandConfig)
    const [teamLogoConfig, setTeamLogoConfig] = useState(logoConfig)

    const _x = transformPercentToPixels(imageConfig?.offset_x ?? 0, w)
    const _y = transformPercentToPixels(imageConfig?.offset_y ?? 0, h)

    const [playerName, setPlayerName] = useState(name)
    const [nameFontSize, setNameFontSize] = useState(nameConfig?.font_size)
    const [nameLetterSpacing, setNameLetterSpacing] = useState(
        nameConfig?.letter_spacing || 0
    )
    const [nameSkew, setNameSkew] = useState({
        x: nameConfig?.skew_x || 0,
        y: nameConfig?.skew_y || 0,
    })
    const [nameRotation, setNameRotation] = useState(nameConfig?.rotation || 0)
    const [nameColor, setNameColor] = useState(nameConfig?.font_color ?? "#FFF")
    const [signatureColor, setSignatureColor] = useState(
        signatureConfig?.font_color ?? "#FFF"
    )
    const [yearColor, setYearColor] = useState(yearConfig?.font_color ?? "#FFF")

    const [cardSignature, setCardSignature] = useState(signature)
    const [cardSignatureFontSize, setCardSignatureFontSize] = useState(
        signatureConfig?.font_size
    )
    const [signatureLetterSpacing, setSignatureLetterSpacing] = useState(
        signatureConfig?.letter_spacing || 0
    )
    const [signatureSkew, setSignatureSkew] = useState({
        x: signatureConfig?.skew_x || 0,
        y: signatureConfig?.skew_y || 0,
    })
    const [signatureRotation, setSignatureRotation] = useState(
        signatureConfig?.rotation || 0
    )

    const [cardYear, setCardYear] = useState(year)
    const [yearFontSize, setYearFontSize] = useState(yearConfig?.font_size)
    const [yearLetterSpacing, setYearLetterSpacing] = useState(
        yearConfig?.letter_spacing || 0
    )
    const [yearSkew, setYearSkew] = useState({
        x: yearConfig?.skew_x || 0,
        y: yearConfig?.skew_y || 0,
    })
    const [yearRotation, setYearRotation] = useState(yearConfig?.rotation || 0)

    const [secondaryLine, setSecondaryLine] = useState(secondary_line ?? "")
    const [secondaryLineFontSize, setSecondaryLineFontSize] = useState(
        secondaryLineConfig.font_size
    )
    const [secondaryLineLetterSpacing, setSecondaryLineLetterSpacing] =
        useState(secondaryLineConfig.letter_spacing || 0)
    const [secondaryLineSkew, setSecondaryLineSkew] = useState({
        x: secondaryLineConfig.skew_x || 0,
        y: secondaryLineConfig.skew_y || 0,
    })
    const [secondaryLineRotation, setSecondaryLineRotation] = useState(
        secondaryLineConfig.rotation || 0
    )
    const [secondaryFontColor, setSecondaryFontColor] = useState(
        secondaryLineConfig?.font_color ?? "#FFF"
    )

    const [scale, setScale] = useState(imageConfig?.zoom)
    const [rotation, setRotation] = useState(imageConfig?.rotation ?? 0)
    const [isSelected, setIsSelected] = useState(false)
    const [points, setPoints] = useState({
        x: _x || 0,
        y: _y || 0,
    })

    const [dataUrl, setDataURL] = useState(null)
    function onSelect(e) {
        const emptySpace = e.target === e.target.getStage()
        if (!emptySpace) {
            setIsSelected((s) => !s)
        }
    }

    function deselect(e) {
        setIsSelected(false)
    }

    function rotate(e) {
        const node = shapeRef?.current as unknown as Node
        const { x, y, rotation } = rotateViaCenter(e, node)
        setPoints({ x, y })
        setRotation(rotation)
    }

    function rotateLogo(e) {
        const node = logoRef?.current as unknown as Node
        const { x: _x, y: _y, rotation } = rotateViaCenter(e, node)
        const x = (_x * 100) / w
        const y = (_y * 100) / h
        setTeamLogoConfig((c) => ({ ...c, x, y, rotation }))
    }

    function rotateBrand(e) {
        const node = brandRef?.current as unknown as Node
        const { x: _x, y: _y, rotation } = rotateViaCenter(e, node)
        const x = (_x * 100) / w
        const y = (_y * 100) / h
        setBrandLogoConfig((c) => ({ ...c, x, y, rotation }))
    }

    async function handleSubmit(e) {
        e.preventDefault()
        const fd = new FormData(e.target)
        const body = transformFormDataForAPI(fd, format)
        mutate(body)
    }

    useEffect(() => {
        imageGroupRef?.current?.hide()
        const dataURL = stageRef?.current?.toDataURL({
            pixelRatio: 1 / dispScale,
        })
        setDataURL(dataURL)
        imageGroupRef?.current?.show()
    }, [
        playerName,
        nameFontSize,
        nameLetterSpacing,

        cardSignature,
        cardSignatureFontSize,
        signatureLetterSpacing,

        cardYear,
        yearFontSize,
        yearLetterSpacing,

        scale,
        rotation,
        points,
        nameColor,
        signatureColor,
        yearColor,
        secondaryFontColor,

        secondaryLine,
        secondaryLineFontSize,
        secondaryLineLetterSpacing,

        secondaryLineSkew,
        secondaryLineRotation,
        nameSkew,
        nameRotation,
        yearSkew,
        yearRotation,
        signatureSkew,
        signatureRotation,
    ])

    function fixText(e) {
        e.preventDefault()
        setPlayerName((n) => n + " ")
        setCardSignature((n) => n + " ")
        setCardYear((n) => n + " ")
        setTimeout(() => {
            setPlayerName((n) => n.trim())
            setCardSignature((n) => n.trim())
            setCardYear((n) => n.trim())
        }, 1)
    }

    if (!data) {
        return null
    }

    return (
        <form
            className="flex flex-col items-center gap-4"
            data-template-format={format}
            onSubmit={handleSubmit}
        >
            <input name="id" type="hidden" value={data.id} />
            {dataUrl && (
                <input name="text_image_data" type="hidden" value={dataUrl} />
            )}
            <div
                className="relative overflow-hidden shadow-2xl"
                style={{
                    width: innerWidth,
                    height: innerHeight,
                    borderRadius: "1rem",
                }}
            >
                <Stage
                    ref={stageRef}
                    width={w}
                    height={h}
                    className="absolute"
                    style={{
                        top: `-${bleedX * dispScale}px`,
                        left: `-${bleedX * dispScale}px`,
                    }}
                    onClick={deselect}
                    onTap={deselect}
                >
                    <Layer onDblClick={onSelect} onDblTap={onSelect}>
                        <Group ref={imageGroupRef}>
                            <BackgroundImage
                                src={data.background}
                                width={w}
                                height={h}
                            />
                            <PlayerImage
                                shapeRef={shapeRef}
                                src={data.person_image}
                                containerWidth={w}
                                containerHeight={h}
                                width={pw}
                                height={ph}
                                config={imageConfig}
                                scale={scale}
                                setScale={setScale}
                                rotation={rotation}
                                setRotation={setRotation}
                                isSelected={isSelected}
                                points={points}
                                setPoints={setPoints}
                            />
                            <ForegroundImage
                                src={data.front_image}
                                width={w}
                                height={h}
                            />
                            <ImageLoader
                                src={data.logo_image}
                                containerWidth={w}
                                containerHeight={h}
                                config={teamLogoConfig}
                                forwardRef={logoRef}
                            />
                            <ImageLoader
                                src={data.brand_logo_image}
                                containerWidth={w}
                                containerHeight={h}
                                config={brandLogoConfig}
                                forwardRef={brandRef}
                            />
                        </Group>
                        <Group>
                            <TextContainer
                                name="name"
                                fontsLoaded={fontsLoaded}
                                text={playerName}
                                letterSpacing={nameLetterSpacing}
                                fontSize={nameFontSize * dispScale}
                                visible={Number(nameFontSize) > 0}
                                {...transformTextConfig(nameConfig, {
                                    width: w,
                                    height: h,
                                })}
                                skew={nameSkew}
                                rotation={nameRotation}
                                fill={nameColor}
                            />
                            <TextContainer
                                name="signature"
                                fontsLoaded={fontsLoaded}
                                text={cardSignature}
                                letterSpacing={signatureLetterSpacing}
                                visible={Number(cardSignatureFontSize) > 0}
                                fontSize={cardSignatureFontSize * dispScale}
                                {...transformTextConfig(signatureConfig, {
                                    width: w,
                                    height: h,
                                })}
                                skew={signatureSkew}
                                rotation={signatureRotation}
                                fill={signatureColor}
                            />
                            <TextContainer
                                name="year"
                                fontsLoaded={fontsLoaded}
                                letterSpacing={yearLetterSpacing}
                                text={cardYear}
                                visible={yearFontSize > 0}
                                fontSize={yearFontSize * dispScale}
                                {...transformTextConfig(yearConfig, {
                                    width: w,
                                    height: h,
                                })}
                                skew={yearSkew}
                                rotation={yearRotation}
                                fill={yearColor}
                            />
                            <TextContainer
                                name="secondary_line"
                                fontsLoaded={fontsLoaded}
                                letterSpacing={secondaryLineLetterSpacing}
                                text={secondaryLine}
                                visible={secondaryLineFontSize > 0}
                                fontSize={secondaryLineFontSize * dispScale}
                                {...transformTextConfig(secondaryLineConfig, {
                                    width: w,
                                    height: h,
                                })}
                                skew={secondaryLineSkew}
                                rotation={secondaryLineRotation}
                                fill={secondaryFontColor}
                            />
                        </Group>
                    </Layer>
                </Stage>
            </div>
            <div
                className="w-full flex flex-col gap-4"
                onClick={deselect}
                tabIndex={-1}
            >
                <div className="flex gap-1">
                    <div className="flex flex-col w-1/2">
                        <label
                            className="!text-xs uppercase"
                            htmlFor={`${data.id}-year`}
                        >
                            Year
                        </label>
                        <input
                            id={`${data.id}-year`}
                            type="text"
                            name="year"
                            className="bg-white p-2 !w-full"
                            value={cardYear}
                            onChange={(e) => setCardYear(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-col w-1/4">
                        <label
                            className="min-w-[100px] !text-xs uppercase"
                            htmlFor={`${data.id}-yearLetterSpacing`}
                        >
                            Letter Spacing
                        </label>
                        <input
                            id={`${data.id}-yearLetterSpacing`}
                            name="year.letter_spacing"
                            type="number"
                            className="bg-white p-2 !w-full"
                            step={0.1}
                            value={yearLetterSpacing}
                            onChange={(e) =>
                                setYearLetterSpacing(Number(e.target.value))
                            }
                        />
                    </div>
                    <div className="flex flex-col w-1/4">
                        <label
                            className="!text-xs uppercase"
                            htmlFor={`${data.id}-yearFontSize`}
                        >
                            Font Size
                        </label>
                        <input
                            id={`${data.id}-yearFontSize`}
                            type="number"
                            name="year.font_size"
                            className="bg-white p-2 !w-full"
                            value={yearFontSize}
                            onChange={(e) => setYearFontSize(e.target.value)}
                        />
                    </div>
                    <div className="flex items-end gap-1">
                        <TextSkewInput
                            skew={yearSkew}
                            setSkew={setYearSkew}
                            name="year"
                        />
                        <TextRotationInput
                            rotation={yearRotation}
                            name="year"
                            setRotation={setYearRotation}
                        />
                        <ColorPicker
                            color={yearColor}
                            name="year"
                            onChange={(color) => setYearColor(color.hexa)}
                        />
                    </div>
                </div>
                <div className="flex gap-1">
                    <div className="flex flex-col w-1/2">
                        <label
                            className="!text-xs uppercase"
                            htmlFor={`${data.id}-name`}
                        >
                            Player Name
                        </label>
                        <input
                            id={`${data.id}-name`}
                            name="name"
                            type="text"
                            className="bg-white p-2 !w-full"
                            value={playerName}
                            onChange={(e) => setPlayerName(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-col w-1/4">
                        <label
                            className="min-w-[100px] !text-xs uppercase"
                            htmlFor={`${data.id}-nameLetterSpacing`}
                        >
                            Letter Spacing
                        </label>
                        <input
                            id={`${data.id}-nameLetterSpacing`}
                            name="name.letter_spacing"
                            type="number"
                            step={0.1}
                            className="bg-white p-2 !w-full"
                            value={nameLetterSpacing}
                            onChange={(e) =>
                                setNameLetterSpacing(Number(e.target.value))
                            }
                        />
                    </div>
                    <div className="flex flex-col w-1/4">
                        <label
                            className="!text-xs uppercase"
                            htmlFor={`${data.id}-nameFontSize`}
                        >
                            Font Size
                        </label>
                        <input
                            id={`${data.id}-nameFontSize`}
                            name="name.font_size"
                            type="number"
                            className="bg-white p-2 !w-full"
                            value={nameFontSize}
                            onChange={(e) => setNameFontSize(e.target.value)}
                        />
                    </div>
                    <div className="flex items-end gap-1">
                        <TextSkewInput
                            skew={nameSkew}
                            setSkew={setNameSkew}
                            name="name"
                        />
                        <TextRotationInput
                            rotation={nameRotation}
                            name="name"
                            setRotation={setNameRotation}
                        />
                        <ColorPicker
                            color={nameColor}
                            name="name"
                            onChange={(color) => setNameColor(color.hexa)}
                        />
                    </div>
                </div>

                <div className="flex gap-1">
                    <div className="flex flex-col w-1/2">
                        <label
                            className="!text-xs uppercase"
                            htmlFor={`${data.id}-secondaryLine`}
                        >
                            Secondary Line
                        </label>
                        <input
                            id={`${data.id}-secondaryLine`}
                            type="text"
                            name="secondary_line"
                            className="bg-white p-2 !w-full"
                            value={secondaryLine}
                            onChange={(e) => setSecondaryLine(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-col w-1/4">
                        <label
                            className="min-w-[100px] !text-xs uppercase"
                            htmlFor={`${data.id}-secondaryLineSpacing`}
                        >
                            Letter Spacing
                        </label>
                        <input
                            id={`${data.id}-secondaryLineSpacing`}
                            name="secondary_line.letter_spacing"
                            type="number"
                            className="bg-white p-2 !w-full"
                            step={0.1}
                            value={secondaryLineLetterSpacing}
                            onChange={(e) =>
                                setSecondaryLineLetterSpacing(
                                    Number(e.target.value)
                                )
                            }
                        />
                    </div>
                    <div className="flex flex-col w-1/4">
                        <label
                            className="!text-xs uppercase"
                            htmlFor={`${data.id}-secondaryLineFontSize`}
                        >
                            Font Size
                        </label>
                        <input
                            id={`${data.id}-secondaryLineFontSize`}
                            type="number"
                            name="secondary_line.font_size"
                            className="bg-white p-2 !w-full"
                            value={secondaryLineFontSize}
                            onChange={(e) =>
                                setSecondaryLineFontSize(e.target.value)
                            }
                        />
                    </div>
                    <div className="flex items-end gap-1">
                        <TextSkewInput
                            skew={secondaryLineSkew}
                            setSkew={setSecondaryLineSkew}
                            name="secondary_line"
                        />
                        <TextRotationInput
                            rotation={secondaryLineRotation}
                            name="secondary_line"
                            setRotation={setSecondaryLineRotation}
                        />
                        <ColorPicker
                            color={secondaryFontColor}
                            name="secondary_line"
                            onChange={(color) =>
                                setSecondaryFontColor(color.hexa)
                            }
                        />
                    </div>
                </div>
                <div className="flex gap-1">
                    <div className="flex flex-col w-1/2">
                        <label
                            className="!text-xs uppercase"
                            htmlFor={`${data.id}-signature`}
                        >
                            Card Signature
                        </label>
                        <input
                            id={`${data.id}-signature`}
                            type="text"
                            name="signature"
                            className="bg-white p-2 !w-full"
                            value={cardSignature}
                            onChange={(e) => setCardSignature(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-col w-1/4">
                        <label
                            className="min-w-[100px] !text-xs uppercase"
                            htmlFor={`${data.id}-signatureLetterSpacing`}
                        >
                            Letter Spacing
                        </label>
                        <input
                            id={`${data.id}-signatureLetterSpacing`}
                            name="signature.letter_spacing"
                            type="number"
                            step={0.1}
                            className="bg-white p-2 !w-full"
                            value={signatureLetterSpacing}
                            onChange={(e) =>
                                setSignatureLetterSpacing(
                                    Number(e.target.value)
                                )
                            }
                        />
                    </div>
                    <div className="flex flex-col w-1/4">
                        <label
                            className="!text-xs uppercase"
                            htmlFor={`${data.id}-signFontSize`}
                        >
                            Font Size
                        </label>
                        <input
                            id={`${data.id}-signFontSize`}
                            type="number"
                            name="signature.font_size"
                            className="bg-white p-2 !w-full"
                            value={cardSignatureFontSize}
                            onChange={(e) =>
                                setCardSignatureFontSize(e.target.value)
                            }
                        />
                    </div>
                    <div className="flex items-end gap-1">
                        <TextSkewInput
                            skew={signatureSkew}
                            setSkew={setSignatureSkew}
                            name="signature"
                        />
                        <TextRotationInput
                            rotation={signatureRotation}
                            name="signature"
                            setRotation={setSignatureRotation}
                        />
                        <ColorPicker
                            color={signatureColor}
                            name="signature"
                            onChange={(color) => setSignatureColor(color.hexa)}
                        />
                    </div>
                </div>

                <div className="flex flex-col">
                    <label>Zoom {scale}</label>
                    <input
                        type="range"
                        className="w-full"
                        onChange={(e) => setScale(Number(e.target.value))}
                        min={MIN_SCALE}
                        step={SCALE_STEP}
                        max={MAX_SCALE}
                        value={scale}
                    />
                </div>
                <div className="flex flex-col">
                    <label>Rotation {rotation + "deg"}</label>
                    <input
                        type="range"
                        onChange={rotate}
                        min={MIN_DEG}
                        step={2}
                        max={MAX_DEG}
                        value={rotation}
                    />
                </div>

                {hasBrandLogo && (
                    <div className="flex flex-col p-2">
                        <label className="!text-xs uppercase">
                            Brand Logo Rotation{" "}
                            {brandLogoConfig.rotation + "deg"}
                        </label>
                        <input
                            type="range"
                            onChange={rotateBrand}
                            min={MIN_DEG}
                            step={0.01}
                            max={MAX_DEG}
                            value={brandLogoConfig.rotation}
                        />
                        <input
                            type="hidden"
                            name="brand.x"
                            value={brandLogoConfig.x}
                        />
                        <input
                            type="hidden"
                            name="brand.y"
                            value={brandLogoConfig.y}
                        />
                        <input
                            type="hidden"
                            name="brand.rotation"
                            value={brandLogoConfig.rotation}
                        />
                    </div>
                )}
                {hasTeamLogo && (
                    <div className="flex flex-col p-2">
                        <label className="!text-xs uppercase">
                            Club Logo Rotation {teamLogoConfig.rotation + "deg"}
                        </label>
                        <input
                            type="range"
                            onChange={rotateLogo}
                            min={MIN_DEG}
                            step={0.01}
                            max={MAX_DEG}
                            value={teamLogoConfig.rotation}
                        />
                        <input
                            type="hidden"
                            name="logo.x"
                            value={brandLogoConfig.x}
                        />
                        <input
                            type="hidden"
                            name="logo.y"
                            value={brandLogoConfig.y}
                        />
                        <input
                            type="hidden"
                            name="logo.rotation"
                            value={brandLogoConfig.rotation}
                        />
                    </div>
                )}
                <div className="flex items-center">
                    <button
                        type="submit"
                        disabled={isLoading}
                        className="!flex !items-center justify-center !bg-indigo-500 !rounded !w-36"
                    >
                        {isLoading ? (
                            <>
                                <Loader2Icon
                                    className="animate-spin mr-2"
                                    size={12}
                                />
                                <span>Saving...</span>
                            </>
                        ) : (
                            <span>Save</span>
                        )}
                    </button>
                    <button onClick={() => downloadURI(dataUrl, "text-layer")}>
                        Download Text
                    </button>
                    <button
                        style={{ display: "none" }}
                        className="js-fixtext"
                        onClick={fixText}
                    >
                        Fix Text
                    </button>
                </div>
            </div>
        </form>
    )
}

export default Container
