import { Trash } from "lucide-react"
import React, { useEffect, useRef, useState } from "react"

function CustomerDetails({
    setPlayerName,
    playerName,
    cardSignature,
    setCardSignature,
    cardYear,
    setCardYear,
    secondaryLine,
    setSecondaryLine,
}) {
    const [showForm, setShowForm] = useState(false)
    const ref = useRef()
    useEffect(() => {
        function onClickOutside(e) {
            const selection = window.getSelection()
            if (
                !(ref.current as HTMLElement).contains(e.target) &&
                !selection?.toString()
            ) {
                setShowForm(false)
            }
        }

        document.addEventListener("click", onClickOutside)

        return () => document.removeEventListener("click", onClickOutside)
    }, [])

    const cx = [
        "border flex items-center px-4 py-2 rounded-md bg-white",
        showForm ? "border-b-0 rounded-b-none z-20" : "",
    ].join(" ")

    const resetButtonCx = [
        "border-r border-t border-b rounded-r-md",
        "px-2 text-red-400"
    ].join(" ")

    return (
        <div className="flex flex-col gap-2 relative bg-white" ref={ref}>
            <button
                className={cx}
                type="button"
                onClick={() => setShowForm(!showForm)}
            >
                Details
            </button>
            {showForm && (
                <div className="z-10 max-h-[600px] overflow-auto grid grid-cols-3 gap-2 min-w-[400px] w-[400px] absolute top-10 bg-white border shadow-md rounded-b-md rounded-r-md p-4">
                    <div className="flex flex-col col-span-3">
                        <div className="flex flex-col col-span-3">
                            <label htmlFor="player-year" className="font-bold">
                                Year
                            </label>
                            <div className="flex items-stretch">
                                <input
                                    id="player-year"
                                    name="player-year"
                                    className="border rounded-l-md p-2 w-full"
                                    onChange={(e) =>
                                        setCardYear(e.target.value)
                                    }
                                    value={cardYear}
                                />
                                <button className={resetButtonCx} onClick={() => setCardYear("")}>
                                    <Trash />
                                </button>
                            </div>
                        </div>

                        <label htmlFor="player-name" className="font-bold">
                            Player Name
                        </label>
                        <div className="flex items-stretch">
                            <input
                                id="player-name"
                                name="player-name"
                                className="border rounded-l-md p-2 w-full"
                                onChange={(e) => setPlayerName(e.target.value)}
                                value={playerName}
                            />
                            <button className={resetButtonCx} onClick={() => setPlayerName("")}>
                                <Trash />
                            </button>
                        </div>
                    </div>

                    <div className="flex flex-col col-span-3">
                        <label htmlFor="secondary-line" className="font-bold">
                            Secondary Line
                        </label>
                        <div className="flex items-stretch">
                            <input
                                id="secondary-line"
                                name="secondary-line"
                                className="border rounded-l-md p-2 w-full"
                                onChange={(e) =>
                                    setSecondaryLine(e.target.value)
                                }
                                value={secondaryLine}
                            />
                            <button className={resetButtonCx} onClick={() => setSecondaryLine("")}>
                                <Trash />
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col col-span-3">
                        <label htmlFor="player-signature" className="font-bold">
                            Signature
                        </label>
                        <div className="flex items-stretch">
                            <input
                                id="player-signature"
                                name="player-signature"
                                className="border rounded-l-md p-2 w-full"
                                onChange={(e) =>
                                    setCardSignature(e.target.value)
                                }
                                value={cardSignature}
                            />
                            <button className={resetButtonCx} onClick={() => setCardSignature("")}>
                                <Trash />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CustomerDetails
