import React, { Suspense } from "react"
import { createRoot } from "react-dom/client"
import App from "./App"
import { QueryClient, QueryClientProvider } from "react-query"
import "../stylesheet/index.css"

const container = document.getElementById("root")
if (container) {
    const root = createRoot(container!)
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    })
    root.render(
        <QueryClientProvider client={queryClient}>
            <Suspense fallback={<p>Loading</p>}>
                <App />
            </Suspense>
        </QueryClientProvider>
    )
}
