import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import FontFaceObserver from "fontfaceobserver"

function useFonts() {
    const [isLoaded, setIsLoaded] = useState(false)
    const { isLoading, data: fonts } = useQuery({
        queryKey: ["fonts"],
        queryFn: async () => {
            const res = await fetch("/api/fonts")
            return await res.json()
        },
    })

    useEffect(() => {
        const newStyle = document.createElement("style")
        fonts?.forEach(({ name, file, css_format }) => {
            newStyle.appendChild(
                document.createTextNode(`
            @font-face {
                font-family: "${name}";
                src: url('${file}') format('${css_format}');
            }`)
            )
        })
        document.head.appendChild(newStyle)
    }, [fonts])

    useEffect(() => {
        async function waitForFontsToLoad() {
            const ps =
                fonts?.map((font) => {
                    const f = new FontFaceObserver(font.name)
                    return f.load(null, 30000)
                }) || []
            return await Promise.allSettled(ps)
        }
        const res = waitForFontsToLoad()
        res.then((r) => {
            const allLoaded = r.every((_r) => _r.status === "fulfilled")
            setIsLoaded(allLoaded)
        })
    }, [fonts])

    return { isLoading, isLoaded, fonts }
}

export default useFonts
