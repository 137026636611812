import { clipSquare } from "../utils"
import {
    CARD_BLEED_X,
    CARD_BLEED_Y,
    CARD_HEIGHT,
    CARD_HEIGHT_W_BLEED,
    CARD_WIDTH,
    CARD_WIDTH_W_BLEED,
} from "../utils/CONSTANTS"

export const SCALE = 0.5
export const innerWidth = CARD_WIDTH * SCALE
export const innerHeight = CARD_HEIGHT * SCALE
export const originalWidth = CARD_WIDTH_W_BLEED * SCALE
export const originalHeight = CARD_HEIGHT_W_BLEED * SCALE

export const clipFunc = (scale) => (ctx) => {
    const x = CARD_BLEED_X * scale
    const y = CARD_BLEED_Y * scale
    return clipSquare(ctx, x, y, innerWidth, innerHeight, 16)
}
