import { useAtom } from "jotai"
import { selectedBackImageAtom, selectedBgAtom, selectedFrontImageAtom, sessionAtom, textLayersAtom } from "../../template_creator/atoms"

function useTemplateDraft() {
    const [background, setBackground] = useAtom(selectedBgAtom)
    const [backImage, setBackImage] = useAtom(selectedBackImageAtom)
    const [frontImage, setFrontImage] = useAtom(selectedFrontImageAtom)
    const [textLayers, setTextLayers] = useAtom(textLayersAtom)
    const [sessionDraft, setSessionDraft] = useAtom(sessionAtom);


    function saveDraft(draftName) {
        const draft = {
            background,
            backImage,
            frontImage,
            textLayers,
        }

        const newDrafts = {
            ...sessionDraft,
            [draftName]: draft
        }

        setSessionDraft(newDrafts)
    }

    function loadDraft(draftName: string) {
        if (draftName in sessionDraft) {
            const selectedDraft = sessionDraft[draftName]
            const {
                background: draftBackground,
                backImage: draftBackImage,
                frontImage: draftFrontImage,
                textLayers: draftTextLayers
            } = selectedDraft;
            setBackground(draftBackground)
            setBackImage(draftBackImage)
            setFrontImage(draftFrontImage)
            setTextLayers(draftTextLayers)
        } else {
            throw new Error('Draft not available')
        }
    }

    return {
        saveDraft,
        loadDraft
    }
}

export default useTemplateDraft;