import React, { RefObject, useEffect, useRef, useState } from "react"
import { Image, Transformer } from "react-konva"
import useImage from "use-image"
import { Html } from "react-konva-utils"
import { decompose_2d_matrix } from "../utils"
import { Node } from "konva/lib/Node"
import { ROTATION_SNAPS } from "../utils/CONSTANTS"

function PlayerImage({
    src,
    width,
    height,
    scale,
    setScale,
    rotation,
    setRotation,
    isSelected,
    shapeRef,
    points: { x, y },
    setPoints,
}: {
    shapeRef: RefObject<Node>
    scale: number
    rotation: number
    isSelected: boolean
    width: number
    height: number
    src: string
    points: {
        x: number
        y: number
    }
}) {
    const [, setIsDragging] = useState(false)
    const trRef = useRef()
    const [image] = useImage(src, "anonymous")
    const oldRotation = useRef(0)
    const startScale = useRef(scale)

    useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            const transformerRef = trRef?.current
            transformerRef?.nodes([shapeRef?.current])
            transformerRef?.getLayer().batchDraw()
        }
    }, [isSelected])

    useEffect(() => {
        const node = shapeRef?.current
        const hammertime = new Hammer(node, { domEvents: true })
        hammertime.get("rotate").set({ enable: true })
        node.on("rotatestart", function (ev) {
            oldRotation.current = ev.evt.gesture.rotation
            startScale.current = node.scaleX()
            node.stopDrag()
            node.draggable(false)
        })

        node.on("rotate", function (ev) {
            if (0 === ev.evt.gesture.rotation) {
                return
            }
            const delta = oldRotation.current - ev.evt.gesture.rotation
            node.rotate(-delta)
            oldRotation.current = ev.evt.gesture.rotation
            node.scaleX(startScale.current * ev.evt.gesture.scale)
            node.scaleY(startScale.current * ev.evt.gesture.scale)
        })

        node.on("rotateend rotatecancel", function (ev) {
            const scaleX = node.scaleX()
            const transform = node.getAbsoluteTransform()
            const matrix = transform.getMatrix()
            const res = decompose_2d_matrix(matrix)
            const { rotation } = res
            const deg = (rotation * 180) / Math.PI
            setRotation(deg)
            const p = {
                x: node.x(),
                y: node.y(),
            }
            setPoints(p)
            setScale(scaleX)
            node.draggable(true)
        })
    }, [])

    function handleDragStart(e) {
        setIsDragging(true)
    }

    function handleDragEnd(e) {
        const node = shapeRef.current as Node
        const transform = node.getAbsoluteTransform()
        const matrix = transform.getMatrix()
        const res = decompose_2d_matrix(matrix)
        setIsDragging(false)
        const p = {
            x: e.target.x(),
            y: e.target.y(),
        }
        setPoints(p)
    }

    return (
        <>
            <Html>
                <input type="hidden" name="image.offset_x" value={x} />
                <input type="hidden" name="image.offset_y" value={y} />
                <input type="hidden" name="image.zoom" value={scale} />
                <input type="hidden" name="image.rotation" value={rotation} />
            </Html>
            <Image
                ref={shapeRef}
                image={image}
                width={width}
                height={height}
                draggable
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                x={x}
                y={y}
                scaleX={scale}
                scaleY={scale}
                rotation={rotation}
                onTransformEnd={(e) => {
                    // transformer is changing scale of the node
                    // and NOT its width or height
                    // but in the store we have only width and height
                    // to match the data better we will reset scale on transform end

                    const node = shapeRef.current as Node
                    const scaleX = node.scaleX()
                    const transform = node.getAbsoluteTransform()
                    const matrix = transform.getMatrix()
                    const res = decompose_2d_matrix(matrix)
                    const { rotation } = res
                    const deg = (rotation * 180) / Math.PI
                    setRotation(deg)
                    const p = {
                        x: node.x(),
                        y: node.y(),
                    }
                    setPoints(p)
                    setScale(scaleX)
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    flipEnabled={false}
                    enabledAnchors={[
                        "top-left",
                        "top-right",
                        "bottom-left",
                        "bottom-right",
                    ]}
                    rotationSnaps={ROTATION_SNAPS}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (
                            Math.abs(newBox.width) < 5 ||
                            Math.abs(newBox.height) < 5
                        ) {
                            return oldBox
                        }
                        return newBox
                    }}
                />
            )}
        </>
    )
}

export default PlayerImage
