import React, { useEffect, useRef, useState } from "react"
import { Rect, Text } from "react-konva"
import { Html } from "react-konva-utils"
import { rotatePoint } from "../utils"
import { Node } from "konva/lib/Node"

function TextContainer({
    x,
    y,
    hAlignment,
    containerWidth,
    containerHeight,
    fontsLoaded,
    skew = { x: 0, y: 0 },
    ...props
}) {
    const prevFontSize = useRef(props.fontSize)
    const [isCenter, setIsCenter] = useState(
        hAlignment.toLowerCase() === "center"
    )
    const [width, setWidth] = useState(0)
    const textRef = useRef()
    const [points, setPoints] = useState({
        x,
        y,
    })
    const [isDragging, setIsDragging] = useState(false)
    const isDebugging = Boolean(import.meta.env.VITE_DEBUG_SHOW_TEXT_BOUNDS)

    function handleDragStart(e) {
        setIsCenter(false)
        setIsDragging(true)
    }

    function handleDragEnd(e) {
        setIsDragging(false)
        const p = {
            x: e.target.x(),
            y: e.target.y(),
        }
        setPoints(p)
    }

    useEffect(() => {
        const difference = (props.fontSize - prevFontSize.current)
        prevFontSize.current = props.fontSize
        if (isCenter) {
            setPoints({
                x: points.x - (difference / 2),
                y: points.y - (difference / 2),
            })
        } else {
            // top left
            setPoints({
                x: points.x,
                y: points.y,
            })
        }
    }, [props.fontSize, points.x, points.y, isCenter])

    useEffect(() => {
        if (width !== textRef?.current?.width()) {
            setWidth(textRef?.current?.width())
        }
    })

    useEffect(() => {
        const node = textRef?.current as unknown as Node
        const _rotation = Number(props.rotation)

        //current rotation origin (0, 0) relative to desired origin - center (node.width()/2, node.height()/2)
        const displayedWidth = node?.width() * node?.scaleX()
        const displayedHeight = node?.height() * node?.scaleY()
        const topLeft = { x: -displayedWidth / 2, y: -displayedHeight / 2 }
        const current = rotatePoint(topLeft, node?.rotation())
        const rotated = rotatePoint(topLeft, _rotation)
        const dx = rotated.x - current.x
        const dy = rotated.y - current.y

        const p = {
            y: isCenter ? points.y : node?.y() + dy,
            x: isCenter
                ? (containerWidth - displayedWidth) / 2
                : node?.x() + dx,
        }
        setPoints(p)
    }, [width, isCenter])

    if (!fontsLoaded) {
        return null
    }

    return (
        <>
            <Html>
                <input
                    type="hidden"
                    value={points.x}
                    name={`${props.name}.x`}
                />
                <input
                    type="hidden"
                    value={points.y}
                    name={`${props.name}.y`}
                />
            </Html>
            {isDebugging && (
                <Rect
                    width={textRef?.current?.width()}
                    height={textRef?.current?.height()}
                    fill="gray"
                    visible={!isDragging}
                    {...points}
                />
            )}
            <Text
                {...props}
                ref={textRef}
                skewX={skew.x}
                skewY={skew.y}
                verticalAlign="top"
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                {...points}
            />
        </>
    )
}

export default TextContainer
