import getEditorDimensions from "./getEditorDimensions"

export interface LogoConfigT {
    x: number
    y: number
    rotation: number
    height: number
}

export interface ImageConfigT {
    offset_x: number
    offset_y: number
    zoom: number
    rotation: number
}

export interface TextConfigT {
    x: number
    y: number
    h_alignment: string
    font_color: string
    font_face: {
        name: string
    }
    font_size: number
    rotation: number
    letter_spacing: number
    skew_x: number
    skew_y: number
}

export interface ConfigurationT {
    year: TextConfigT
    name: TextConfigT
    signature: TextConfigT
    secondary_line: TextConfigT
    logo: LogoConfigT
    brand: LogoConfigT
    image: ImageConfigT
}

export interface DataT {
    id: string
    name: string
    format: string
    year: string
    signature: string
    secondary_line: string
    background: string
    front_image: string
    back_image: string
    person_image: string
    person_image_meta: {
        height: number
        width: number
    }
    logo_image: string
    brand_logo_image: string
    configuration: ConfigurationT
}

const degToRad = Math.PI / 180

export const rotatePoint = ({ x, y }, deg) => {
    const rcos = Math.cos(deg * degToRad),
        rsin = Math.sin(deg * degToRad)
    return { x: x * rcos - y * rsin, y: y * rcos + x * rsin }
}

export function decompose_2d_matrix(mat) {
    var a = mat[0]
    var b = mat[1]
    var c = mat[2]
    var d = mat[3]
    var e = mat[4]
    var f = mat[5]

    var delta = a * d - b * c

    let result = {
        translation: [e, f],
        rotation: 0,
        scale: [0, 0],
        skew: [0, 0],
    }

    if (a !== 0 || b !== 0) {
        var r = Math.sqrt(a * a + b * b)
        result.rotation = b > 0 ? Math.acos(a / r) : -Math.acos(a / r)
        result.scale = [r, delta / r]
        result.skew = [Math.atan((a * c + b * d) / (r * r)), 0]
    } else if (c !== 0 || d !== 0) {
        var s = Math.sqrt(c * c + d * d)
        result.rotation =
            Math.PI / 2 - (d > 0 ? Math.acos(-c / s) : -Math.acos(c / s))
        result.scale = [delta / s, s]
        result.skew = [0, Math.atan((a * c + b * d) / (s * s))]
    } else {
        // a = b = c = d = 0
    }

    return result
}

export function transformTextConfig(
    { x, y, h_alignment, font_face, rotation, font_color }: TextConfigT,
    { width, height }: { width: number; height: number }
) {
    return {
        x: (x / 100) * width,
        y: (y / 100) * height,
        hAlignment: h_alignment?.toLowerCase() ?? "",
        fill: font_color?.toLowerCase() ?? "",
        fontFamily: font_face?.name ?? "Helvetica, Arial",
        containerWidth: width,
        containerHeight: height,
        rotation: rotation || 0,
    }
}

export function getPositionPercentage(key, value, w, h) {
    switch (key) {
        case "offset_x":
        case "x":
            return (Number(value) / w) * 100
        case "offset_y":
        case "y":
            return (Number(value) / h) * 100
        case "font_color":
            return value
        default:
            return Number(value)
    }
}

export function transformFormDataForAPI(fd: FormData, format: string = "card") {
    const { w, h } = getEditorDimensions(format)
    const values = Array.from(fd.entries()).reduce(
        (acc, [key, value]) => {
            const [a, b] = key.split(".")
            const v = getPositionPercentage(b, value, w, h)
            const currentConfig = acc.configuration
            const configuration = {
                ...currentConfig,
                [a]: {
                    ...currentConfig[a],
                    ...(b ? { [b]: v } : {}),
                },
            }

            return {
                ...acc,
                configuration,
                ...(!b ? { [a]: value } : {}),
            }
        },
        { configuration: {} }
    )
    delete values.configuration.id
    delete values.configuration.text_image_data
    return values
}

export function clipSquare(ctx, x, y, width, height, radius) {
    ctx.beginPath()
    ctx.moveTo(x + radius, y)
    ctx.lineTo(x + width - radius, y)
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
    ctx.lineTo(x + width, y + height - radius)
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
    ctx.lineTo(x + radius, y + height)
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
    ctx.lineTo(x, y + radius)
    ctx.quadraticCurveTo(x, y, x + radius, y)
    ctx.closePath()
}

export function rotateViaCenter(e, node) {
    const _rotation = Number(e.target.value)

    //current rotation origin (0, 0) relative to desired origin - center (node.width()/2, node.height()/2)
    const displayedWidth = node?.width() * node?.scaleX()
    const displayedHeight = node?.height() * node?.scaleY()
    const topLeft = { x: -displayedWidth / 2, y: -displayedHeight / 2 }
    const current = rotatePoint(topLeft, node?.rotation())
    const rotated = rotatePoint(topLeft, _rotation)
    const dx = rotated.x - current.x,
        dy = rotated.y - current.y

    const p = {
        x: node?.x() + dx,
        y: node?.y() + dy,
    }

    return {
        ...p,
        rotation: _rotation,
    }
}

export function convertStoreForAPI(store) {
    return {
        template_name: store.template_name,
        background_id: store.background_id,
        back_template_id: store.back_template_id,
        name_position: store.name_position,
        year_position: store.year_position,
        secondary_line_position: store.secondary_line_position,
        signature_position: store.signature_position,
        default_card_logo_id: "",
        logo_position: { offset_y: 3.0, zoom: 1.0, rotation: 0.0 },
        brand_logo_id: "",
        brand_logo_position: { offset_y: 3.0, zoom: 1.0, rotation: 0.0 },
        tag_image_id: "",
        tag_image_position: { offset_y: 3.0, zoom: 1.0, rotation: 0.0 },
        front_image_data: store.front_image_data,
        back_image_data: store.back_image_data,
        is_available_on_demo: true,
    }
}

export function extractNameAndTeam(filename: string): {
    name: string
    team: string
} {
    const filenameParts = filename.includes("-")
        ? filename.split("-")
        : filename

    if (Array.isArray(filenameParts)) {
        // expected format is "Team Name - Player Name"
        const [team, name] = filenameParts
        return { name: name?.trim(), team: team?.trim() }
    }
    return { name: filenameParts?.trim(), team: "Team Name" }
}
