import { useSetAtom } from "jotai";
import { selectedTextAtom, selectedTextNodeAtom } from "../../template_creator/atoms";

function useResetTextSelection() {
    const setSelectedText = useSetAtom(selectedTextAtom);
    const setSelectedTextNode = useSetAtom(selectedTextNodeAtom)

    function resetTextSelection() {
        setSelectedText(null);
        setSelectedTextNode(null)
    }

    return resetTextSelection
}

export default useResetTextSelection