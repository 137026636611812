import React, { useState } from "react"
import useFonts from "../components/hooks/useFonts"
import { useQueries } from "react-query"
import CardTemplateSelection, { TCardTemplate } from "./CardSelection"
import Display from "./Display"
import CustomerDetails from "./CustomerDetails"
import PlayerImageUploader from "./PlayerImageUploader"
import LogoSelection from "./LogoSelection"
interface TBackground {
    id: string
    name: string
    image: string
}

interface TLogo extends TBackground {
    club: string
}

export const fetchBackgrounds = async () => {
    const res = await fetch("/api/backgrounds")
    return await res.json()
}

export const fetchTemplates = async () => {
    const res = await fetch("/api/card_templates")
    return await res.json()
}
export const fetchLogos = async () => {
    const res = await fetch("/api/logos/club")
    return await res.json()
}

export const fetchSportsLogos = async () => {
    const res = await fetch("/api/logos/sports")
    return await res.json()
}

function Home() {
    const { isLoaded } = useFonts()
    const [landscapeMode, setLandscapeMode] = useState(false)
    const [template, setTemplate] = useState("")
    const [playerName, setPlayerName] = useState("Player Name")
    const [cardYear, setCardYear] = useState("2024")
    const [playerImage, setPlayerImage] = useState(null)
    const [playerMeta, setPlayerMeta] = useState({
        width: 0,
        height: 0,
        playerId: "",
    })
    const [cardSignature, setCardSignature] = useState("Signature")
    const [secondaryLine, setSecondaryLine] = useState("Secondary line")
    const [selectedBackground] = useState(null)
    const [selectedClubLogoId, setSelectedClubLogoId] = useState(null)
    const [selectedSportId, setSelectedSportId] = useState(null)
    const [rotation, setRotation] = useState(0)
    const [points, setPoints] = useState({ x: 0, y: 0 })
    const [scale, setScale] = useState(1)

    const [backgrounds, templates, logos, sports] = useQueries([
        {
            queryKey: ["backgrounds"],
            queryFn: fetchBackgrounds,
            staleTime: Infinity,
        },
        {
            queryKey: ["card_templates"],
            queryFn: fetchTemplates,
            staleTime: Infinity,
        },
        {
            queryKey: ["club_logos"],
            queryFn: fetchLogos,
            staleTime: Infinity,
        },
        {
            queryKey: ["sports_logos"],
            queryFn: fetchSportsLogos,
            staleTime: Infinity,
        },
    ])

    const templatesWithBg = templates?.data?.map((t) => {
        const background_image = backgrounds.data?.find(
            (b) => b.id === t.default_background_id
        )
        return {
            ...t,
            background_image: background_image?.image,
        }
    })

    const selectedTemplate: TCardTemplate | undefined = templates?.data?.find(
        (t: TCardTemplate) => t.id === template
    )

    const background: TBackground | undefined = backgrounds?.data?.find(
        (b: TBackground) => b.id === selectedTemplate?.default_background_id
    )

    const selectedClubLogo: TLogo | undefined = logos?.data?.find(
        (l) => l.id === selectedClubLogoId
    ) ?? {
        id: selectedTemplate?.default_club_logo_id ?? null,
        image: selectedTemplate?.default_card_logo_image ?? null,
    }

    if (isLoaded) {
        return (
            <div className="p-2 flex flex-col">
                <div className="flex items-start gap-2">
                    <CardTemplateSelection
                        templates={templatesWithBg ?? []}
                        setTemplate={setTemplate}
                        selected={template}
                    />
                    {/* <BackgroundSelection
                        backgrounds={backgrounds.data ?? []}
                        setBackground={setBackground}
                        selected={selectedBackground}
                    /> */}
                    <LogoSelection
                        data={logos.data ?? []}
                        setLogoId={setSelectedClubLogoId}
                        selected={selectedClubLogoId}
                        title="Club Logos"
                    />
                    <LogoSelection
                        data={sports.data ?? []}
                        setLogoId={setSelectedSportId}
                        selected={selectedSportId}
                        title="Sports Logo"
                        cover
                        darkMode
                    />
                    <PlayerImageUploader
                        setPlayerImage={setPlayerImage}
                        setPlayerMeta={setPlayerMeta}
                        playerImage={playerImage}
                        setPlayerName={setPlayerName}
                        setSecondaryLine={setSecondaryLine}
                    />
                    <CustomerDetails
                        playerName={playerName}
                        setPlayerName={setPlayerName}
                        cardSignature={cardSignature}
                        setCardSignature={setCardSignature}
                        cardYear={cardYear}
                        setCardYear={setCardYear}
                        secondaryLine={secondaryLine}
                        setSecondaryLine={setSecondaryLine}
                    />
                    <div className="border flex items-center px-4 py-2 rounded-md bg-white gap-2">
                        <input
                            className="rounded"
                            id="landscapeview"
                            type="checkbox"
                            checked={landscapeMode}
                            onChange={() => setLandscapeMode(!landscapeMode)}
                        />
                        <label htmlFor="landscapeview" className="select-none">Landscape View</label>
                    </div>
                </div>
                <div className="relative w-full h-[calc(100dvh-160px)] flex items-center justify-end">
                    <Display
                        key={template}
                        templateId={template}
                        format={selectedTemplate?.format}
                        backgroundId={selectedBackground?.id}
                        background={
                            selectedBackground?.image ?? background?.image
                        }
                        frontImage={selectedTemplate?.front_image}
                        logoImage={selectedClubLogo?.image}
                        brandLogoImage={selectedTemplate?.brand_logo_image}
                        clubLogoId={selectedClubLogo?.id}
                        sportLogoId={selectedSportId}
                        logoConfig={selectedTemplate?.logo_position ?? {}}
                        brandConfig={
                            selectedTemplate?.brand_logo_position ?? {}
                        }
                        nameConfig={selectedTemplate?.name_position ?? {}}
                        signatureConfig={
                            selectedTemplate?.signature_position ?? {}
                        }
                        secondaryLineConfig={
                            selectedTemplate?.secondary_line_position ?? {}
                        }
                        yearConfig={selectedTemplate?.year_position ?? {}}
                        playerName={playerName}
                        cardYear={cardYear}
                        cardSignature={cardSignature}
                        secondaryLine={secondaryLine}
                        personImage={playerImage}
                        personImageMeta={playerMeta}
                        rotation={rotation}
                        scale={scale}
                        setRotation={setRotation}
                        setScale={setScale}
                        points={points}
                        setPoints={setPoints}
                        landscape={landscapeMode}
                    />
                </div>
            </div>
        )
    }

    return null
}

export default Home
