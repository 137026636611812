import React from "react"
import { Popover } from "@headlessui/react"
import ToolbarPopover from "./TooolbarPopover"
import { Search } from "lucide-react"

function PopoverContainer({
    icon,
    title,
    onButtonClick,
    label,
    children,
    query,
    setQuery,
}) {
    const htmlFor = title
        .split(" ")
        .map((t) => t.toLowerCase())
        .join("-")
    return (
        <Popover className="relative">
            {({ open }) => (
                <ToolbarPopover
                    open={open}
                    title={title}
                    icon={icon}
                    onButtonClick={onButtonClick}
                >
                    <Popover.Panel className="z-100 left-12 top-0 absolute bg-slate-100 shadow-md w-screen overflow-hidden rounded-md transform gap-2 max-w-sm flex flex-col py-4">
                        <p className="uppercase font-bold px-4">{label}</p>
                        <div className="flex items-stretch px-4">
                            <label
                                htmlFor={htmlFor}
                                className="flex items-center py-2 px-4 border rounded-l-md"
                            >
                                <Search className="w-4 h-4" />
                            </label>
                            <input
                                className="border-r border-y px-3 py-2 rounded-r-md w-full"
                                type="text"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                id={htmlFor}
                                placeholder="Search"
                            />
                        </div>
                        <div className="flex flex-wrap w-full gap-2 max-h-[50vh] overflow-y-auto px-4">
                            {children}
                        </div>
                    </Popover.Panel>
                </ToolbarPopover>
            )}
        </Popover>
    )
}

export default PopoverContainer
