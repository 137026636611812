import React from "react"
import { SnackbarProvider } from "notistack"
import Workspace from "./Workspace"
import Card from "./Card"
import BackCard from "./BackCard"

function TemplateCreator() {
    return (
        <>
            <SnackbarProvider />
            <Workspace frontCard={<Card />} backCard={<BackCard />} />
        </>
    )
}

export default TemplateCreator
