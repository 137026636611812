import React, { useRef } from "react"
import { Group, Rect } from "react-konva"
import { CARD_BLEED_X, CARD_BLEED_Y } from "../utils/CONSTANTS"
import { useAtomValue } from "jotai"
import { selectedBackImageAtom } from "./atoms"
import {
    SCALE,
    clipFunc,
    innerHeight,
    innerWidth,
    originalHeight,
    originalWidth,
} from "./layout"
import BackgroundImage from "../components/BackgroundImage"

const clipFuncWithScale = clipFunc(SCALE)

function BackCard() {
    const background = useAtomValue(selectedBackImageAtom)
    const shadowRef = useRef()

    return (
        <>
            <Rect
                ref={shadowRef}
                fill="black"
                opacity={0.5}
                shadowColor="black"
                shadowBlur={10}
                shadowOpacity={0.5}
                cornerRadius={16}
                shadowOffsetX={4}
                shadowOffsetY={4}
                listening={false}
                width={innerWidth}
                height={innerHeight}
                x={CARD_BLEED_X * SCALE}
                y={CARD_BLEED_Y * SCALE}
                listening={false}
            />

            <Group clipFunc={clipFuncWithScale} x={0} y={0} listening={false}>
                <BackgroundImage
                    src={background?.image}
                    width={originalWidth}
                    height={originalHeight}
                />
            </Group>
        </>
    )
}

export default BackCard
