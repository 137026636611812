import { ColorResult, Chrome } from "@uiw/react-color"
import React, { useEffect, useRef, useState } from "react"

function ColorPicker({
    color,
    onChange,
    name,
    showLabel = false,
}: {
    color: string
    onChange: (color: ColorResult) => void
    showLabel: boolean
    name?: string
}) {
    const ref = useRef(null)
    const [displayPicker, setDisplayPicker] = useState(false)
    useEffect(() => {
        function handleClickOutside(event) {
            if (
                ref.current &&
                !(ref.current as HTMLElement).contains(event.target)
            ) {
                setDisplayPicker(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [ref])
    return (
        <div className="relative" ref={ref}>
            {name && (
                <input
                    type="hidden"
                    value={color}
                    name={`${name}.font_color`}
                />
            )}
            <div className="flex flex-col gap-1">
                {showLabel && (
                    <span className="shrink-0 text-xs uppercase">
                        Font&nbsp;Color
                    </span>
                )}
                <label
                    style={{ backgroundColor: color }}
                    className="w-4 h-4 block border shrink-0"
                    onClick={() => setDisplayPicker(true)}
                    title="Change font color"
                />
            </div>
            {displayPicker && (
                <div className="absolute z-10">
                    <Chrome color={color} onChange={onChange} />
                </div>
            )}
        </div>
    )
}

export default ColorPicker
